/* eslint-disable array-callback-return */
import * as React from 'react'
import { Grid, Button, Modal, Icon, Header } from 'semantic-ui-react'
import { RewardTarget } from '../types/RewardModels'
import { CheckElementRewardTarget } from './CheckElementRewardTarget'

interface CreateRewardsProps {
  checkopen: boolean,
  idarray: string,
  handleno: any,
  handleyes: any
}

interface CreateRewardsState {
  idCheck: string[],
  idView: string[],
  formInput: string,
  selectCheck: boolean,
  selectClear: boolean,
  loading: boolean,
  loadingbutton: boolean,
  open: boolean
}

export class RewardsTargetList extends React.PureComponent<
  CreateRewardsProps,
  CreateRewardsState
> {
  state: CreateRewardsState = {
    idCheck: [],
    idView: [],
    formInput: '',
    selectCheck: false,
    selectClear: true,
    loading: false,
    loadingbutton: false,
    open: true
  }

  handleSelectAll = () => {
    if (this.state.selectCheck) {  
      let idArr = ['all']
      let idArrNew = idArr    
      this.setState({ idCheck: idArrNew, selectCheck: false })
    } else {
      let idArr = ['all']
      let idArrNew = idArr
    this.setState({ idCheck: idArrNew, selectCheck: true })
    }
  }

  handleClear = () => {
    if (this.state.selectClear) {
    this.setState({ idCheck: [], selectClear: false })
    } else {
      this.setState({ idCheck: [], selectClear: true }) 
    }
  }

  handleCreateAllUserId = (userIdarray: string[]) => {
    let idCheckNew = userIdarray
      this.setState({ idView: idCheckNew })
  }

  handleDeleteAllUserId = () => {
    this.setState({ idView: [] })
  }

  handleIdCheckPush = (userId: string) => {
    let idCheckIdNew = this.state.idCheck.filter(
      id => id !== userId
    )
    let idCheckNew = this.state.idCheck.concat([userId])
    if (this.state.idCheck[0] === 'all') {
      idCheckNew = idCheckIdNew
    }
      this.setState({ idCheck: idCheckNew })
      console.log('checkpush: ' + userId + 'idcheck: ' + this.state.idCheck)
  }

  handleIdCheckDelete = (userId: string) => {
    console.log({"filternew": [userId]})
    let idCheckNew = this.state.idCheck.filter(
      id => id !== userId
    )
    console.log({"filterold": idCheckNew})
    if (this.state.idCheck[0] === 'all') {
      if (this.state.idCheck.length > 1) {
        idCheckNew = this.state.idCheck.concat([userId])
        console.log({"concat": idCheckNew})
      } else {
        idCheckNew = ['all'].concat([userId])
        console.log({"concatnew": idCheckNew})
      }
    }
    console.log({"final": idCheckNew})
      this.setState({ idCheck: idCheckNew })
      console.log('checkdelete: ' + userId + 'idcheck: ' + this.state.idCheck)
  }

  shouldComponentUpdate(nextProps: CreateRewardsProps, nextState: CreateRewardsState) {
    if (nextState.idView !== this.state.idView) {return false}
    return true
  }

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ formInput: event.target.value })
  }

  handleNo = () => {
    this.setState({open: false, idCheck: [],
      idView: [],
      formInput: '',
      selectCheck: false,
      selectClear: true})
      this.props.handleno()
  }

  handleYes = async () => {
    this.setState({loading: true})
    await this.props.handleyes(this.state.idCheck)
    this.setState({open: false, idCheck: [],
      idView: [],
      formInput: '',
      selectCheck: false,
      selectClear: true,
    loading: false})
  }

  renderAdminUsersListMethod() {
    let usersState = 'zero'
    if (this.state.idCheck.length > 1) {
      usersState = 'many'
    }
    if (this.state.idCheck.length === 1) {
      usersState = 'one'
    }
    switch (usersState) {
      case 'zero':
        return this.renderUsersZero()
      case 'many':
        return this.renderUsersMany()
      case 'one':
        return this.renderUsersOne()
      default:
        return this.renderUsersZero()
    }
  }

  renderUsersMany() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    const rewards: RewardTarget[] = JSON.parse(this.props.idarray)
    return (
      <div>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              {this.state.idCheck[0] === 'all' && <Button
                color="orange"
                size="small"
                className="select-button"
                disabled
              >
                Include new Customers
              </Button>}
              {this.state.idCheck[0] !== 'all' && <Button
                color="orange"
                size="small"
                className="select-button"
                disabled
              >
                Do not include new Customers
              </Button>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '70vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={4} style={{minWidth: '72em'}}>
        <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Select</Grid.Column>          
          </Grid.Row>
          {rewards.map((reward) => {
            if (!this.state.formInput) {
              idArray.push(reward.id)
              if (reward.id === rewards[rewards.length - 1].id) {
                this.handleCreateAllUserId(idArray)
             }
              return (
                <CheckElementRewardTarget
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
            )            
            } else {
                if (reward.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || reward.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(reward.id)
                  if (reward.id === rewards[rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementRewardTarget
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (reward.id === rewards[rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
                            
            }            
          })}
          {rewards.length === 0 && <Grid.Row column={4} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersZero() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    const rewards: RewardTarget[] = JSON.parse(this.props.idarray)
    return (
      <div>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              {this.state.idCheck[0] === 'all' && <Button
                color="orange"
                size="small"
                className="select-button"
                disabled
              >
                Include new Customers
              </Button>}
              {this.state.idCheck[0] !== 'all' && <Button
                color="orange"
                size="small"
                className="select-button"
                disabled
              >
                Do not include new Customers
              </Button>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '70vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={4} style={{minWidth: '72em'}}>
        <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Select</Grid.Column>          
          </Grid.Row>
          {rewards.map((reward) => {
            if (!this.state.formInput) {
              idArray.push(reward.id)
              if (reward.id === rewards[rewards.length - 1].id) {
                this.handleCreateAllUserId(idArray)
             }
              return (
                <CheckElementRewardTarget
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
            )            
            } else {
                if (reward.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || reward.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(reward.id)
                  if (reward.id === rewards[rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementRewardTarget
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (reward.id === rewards[rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
                            
            }            
          })}
          {rewards.length === 0 && <Grid.Row column={4} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersOne() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    const rewards: RewardTarget[] = JSON.parse(this.props.idarray)
    return (
      <div>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              {this.state.idCheck[0] === 'all' && <Button
                color="orange"
                size="small"
                className="select-button"
                disabled
              >
                Include new Customers
              </Button>}
              {this.state.idCheck[0] !== 'all' && <Button
                color="orange"
                size="small"
                className="select-button"
                disabled
              >
                Do not include new Customers
              </Button>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '70vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={4} style={{minWidth: '72em'}}>
        <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Name</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Select</Grid.Column>          
          </Grid.Row>
          {rewards.map((reward) => {
            if (!this.state.formInput) {
              idArray.push(reward.id)
              if (reward.id === rewards[rewards.length - 1].id) {
                this.handleCreateAllUserId(idArray)
             }
              return (
                <CheckElementRewardTarget
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
            )            
            } else {
                if (reward.name.toLowerCase().includes(this.state.formInput.toLowerCase()) || reward.email.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(reward.id)
                  if (reward.id === rewards[rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementRewardTarget
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (reward.id === rewards[rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
                            
            }            
          })}
          {rewards.length === 0 && <Grid.Row column={4} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

render() {
  return (
    <Modal
        onClose={() => this.setState({open: false})}
        onOpen={() => this.setState({open: true})}
        open={this.state.open}
        size='fullscreen'
      >
        <Header textAlign="center" icon><Icon color='violet' name='arrow alternate circle down' />Choose your Targets:</Header>
        <Modal.Content>
       {this.renderAdminUsersListMethod()}
        </Modal.Content>
        <Modal.Actions>
          <Button color='violet' loading={this.state.loading} onClick={this.handleNo}>
           <Icon name='remove' /> Back to Configuration
          </Button>
          <Button color='violet' loading={this.state.loading} onClick={this.handleYes}>
           <Icon name='checkmark' /> Confirm and Create
          </Button>
        </Modal.Actions>
      </Modal>
  )
}

}