/* eslint-disable array-callback-return */
import * as React from 'react'
import { Grid, Button, Popup, Loader, Message, Image, Form, Label } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { GoldenContainerNew } from './GoldenContainerNewLogo'
import { UserModel } from '../types/UserModel'
import { RewardEmpModel } from '../types/GoldenCoinModel'
import { getInfoUser, getUsers } from '../api/users-api'
import { getGolden } from '../api/users-api'

const { bottomGoldAvailable, digitalGoldUrl, maxPointsMonth, maxPointsYear, newGoldenBadgeLargeSrc } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)
const controllerBlur = new AbortController()
const controllerFocus = new AbortController()
const divStyle = {
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  padding: '0.6em 0.1em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF'
}

const divBadgeStyle = {
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF',
  marginBottom: '0.2em'
}

interface GoldenCoinProps {
  auth: Auth,
  history: any
}

interface GoldenCoinState {
  loading: boolean,
  loadingbutton: boolean,
  numberofusers: string,
  userBalance: string,
  userStatus: string,
  balanceGained: string,
  balanceRewarded: string,
  employees: RewardEmpModel[],
  companyName: string,
  usergroup: string,
  email: string,
  updateCheck: boolean,
  reservedBalance: string,
  minimumTokens: string,
  maxPoints: string,
  conversion_att: string,
  base_conversion: string
}

function fixRounding(value: number, precision: number) {
  let power = Math.pow(10, precision || 0)
  return Math.round(value * power) / power
}

async function getBalance(token: any, context: any) {
  try {
    let minimumTokens = '0'
    let balance = '0'
    let conversion = '0.0001'
    let status = 'none'
    let balance_gained: any = '0'
  let balance_rewarded: any = '0'
    let reserved = '0'
    let empArr = []
    const userid: string = token.payload.sub
    context.setState({loadingbutton: true})
        const userPaymentInfo: UserModel = await getInfoUser(token, userid)
        const usergroup = userPaymentInfo.usergroup
        const email = userPaymentInfo.email
        const duration = userPaymentInfo.timepayment
        const numberofusers = userPaymentInfo.numberofusers
        reserved = userPaymentInfo.reserved_coins || '0'
        const companyName = userPaymentInfo.company_name || 'none'
    const users = await getUsers(token)
    if (usergroup === 'companies') {
      for (let i = 0; i < users.length; i++) {
        const element = users[i];
        const resultBalance = await getGolden(context.props.auth.getIdToken(), element.id)
        if (element.type === 'company') {
    balance = resultBalance.balance
    status = resultBalance.status
    balance_gained = resultBalance.balance_gained || '0'
    balance_rewarded = resultBalance.balance_reward || '0'
        } else {
          let userObj: RewardEmpModel = {
    balance: resultBalance.balance,
    email: element.email,
    status: resultBalance.status,
    balance_gained: resultBalance.balance_gained || '0',
    balance_rewarded: resultBalance.balance_reward || '0',
    total_rewards: '0'
          }
          const totalRewards = fixRounding((parseFloat(userObj.balance_gained) + parseFloat(userObj.balance_rewarded)), 2).toString()
  userObj.total_rewards = totalRewards
          empArr.push(userObj)
        }
    }
              if (duration === 'month') {
  const opMinMonth = fixRounding(((parseInt(maxPointsMonth) * parseFloat(conversion)) * parseInt(numberofusers)), 2)
  minimumTokens = opMinMonth.toString()
  } else {
  const opMinYear = fixRounding(((parseInt(maxPointsYear) * parseFloat(conversion)) * parseInt(numberofusers)), 2)
  minimumTokens = opMinYear.toString()
              }
    } else {
      if (usergroup === 'employees' || usergroup === 'guests' || usergroup === 'members') {
        const resultBalance = await getGolden(context.props.auth.getIdToken(), userid)
        balance = resultBalance.balance
      status = resultBalance.status
      balance_gained = resultBalance.balance_gained || '0'
      balance_rewarded = resultBalance.balance_reward || '0'  
    }
    }
  context.setState({usergroup, companyName, userBalance: balance, employees: empArr, userStatus: status, reservedBalance: reserved, email, loadingbutton: false, minimumTokens, balanceGained: balance_gained, balanceRewarded: balance_rewarded})
  } catch (error) {
    context.setState({loadingbutton: false})
      console.log(error)
  }
}

export class GoldenCoinResNew extends React.PureComponent<
GoldenCoinProps,
GoldenCoinState
> {
  state: GoldenCoinState = {
    loading: false,
    loadingbutton: false,
    numberofusers: '1',
    userBalance: '0',
    balanceGained: '0',
    balanceRewarded: '0',
    userStatus: 'none',
    employees: [],
    companyName: 'none',
    usergroup: 'none',
    email: 'none',
    updateCheck: false,
    reservedBalance: '0',
    minimumTokens: '0',
    maxPoints: '0',
    conversion_att: 'none',
    base_conversion: '0.0001'
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.updateCheck === this.state.updateCheck) {
try {
  let minimumTokens = '0'
  let balance = '0'
  let status = 'none'
  let reserved = '0'
  let balance_gained: any = '0'
  let balance_rewarded: any = '0'
let conversion = '0.0001'
  let empArr: any[] = []
  const userid: string = this.props.auth.getIdToken().payload.sub
      const userPaymentInfo: UserModel = await getInfoUser(this.props.auth.getIdToken(), userid)
      reserved = userPaymentInfo.reserved_coins || '0'
      const usergroup = userPaymentInfo.usergroup
      const email = userPaymentInfo.email
      const duration = userPaymentInfo.timepayment
      const numberofusers = userPaymentInfo.numberofusers
      const companyName = userPaymentInfo.company_name || 'none'
  const users = await getUsers(this.props.auth.getIdToken())
  if (usergroup === 'companies') {
    for (let i = 0; i < users.length; i++) {
      const element = users[i];
      const resultBalance = await getGolden(this.props.auth.getIdToken(), element.id)
      if (element.type === 'company') {
  balance = resultBalance.balance
  status = resultBalance.status
  balance_gained = resultBalance.balance_gained || '0'
  balance_rewarded = resultBalance.balance_reward || '0'
      } else {
        let userObj: RewardEmpModel = {
  balance: resultBalance.balance,
  email: element.email,
  status: resultBalance.status,
  balance_gained: resultBalance.balance_gained || '0',
  balance_rewarded: resultBalance.balance_reward || '0',
  total_rewards: '0'
        }
        const totalRewards = fixRounding((parseFloat(userObj.balance_gained) + parseFloat(userObj.balance_rewarded)), 2).toString()
userObj.total_rewards = totalRewards
        empArr.push(userObj)
      }
  }
            if (duration === 'month') {
const opMinMonth = fixRounding(((parseInt(maxPointsMonth) * parseFloat(conversion)) * parseInt(numberofusers)), 2)
minimumTokens = opMinMonth.toString()
} else {
const opMinYear = fixRounding(((parseInt(maxPointsYear) * parseFloat(conversion)) * parseInt(numberofusers)), 2)
minimumTokens = opMinYear.toString()
            }
  } else {
    if (usergroup === 'employees' || usergroup === 'guests' || usergroup === 'members') {
      const resultBalance = await getGolden(this.props.auth.getIdToken(), userid)
      balance = resultBalance.balance
    status = resultBalance.status
    balance_gained = resultBalance.balance_gained || '0'
    balance_rewarded = resultBalance.balance_reward || '0'  
  }
  }
this.setState((prevState: any) => ({usergroup, companyName, userBalance: balance, employees: empArr, userStatus: status, reservedBalance: reserved, email, updateCheck: !prevState.updateCheck, minimumTokens, balanceGained: balance_gained, balanceRewarded: balance_rewarded}))
} catch (error) {
    console.log(error)
}
    }
  }

  openInNewTab = (url: any) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

handleBoost = () => {
  let remainingBalance = 0
        if (this.state.reservedBalance !== this.state.userBalance) {
remainingBalance = fixRounding(parseFloat(this.state.userBalance) - parseFloat(this.state.reservedBalance), 2)
        }
  let infoString = this.state.companyName + ',' + remainingBalance + ',' + this.state.reservedBalance + ',' + this.state.minimumTokens + ',' + this.state.conversion_att + ',' + this.state.base_conversion
  this.props.history.replace(`/goldenboost/${infoString}`)
}

componentWillUnmount(): void {
  controllerBlur.abort()
controllerFocus.abort()
}

  async componentDidMount() {
    const context = this
    window.addEventListener("focus", function(event)
    { 
      getBalance(context.props.auth.getIdToken(), context)
    }, {signal: controllerFocus.signal})
    window.addEventListener("blur", function(event)
{ 
  getBalance(context.props.auth.getIdToken(), context)
}, {signal: controllerBlur.signal})
try {
  this.setState({loading: true})
  let maxPoints = '0'
  let minimumTokens = '0'
  let balance = '0'
  let balance_gained: any = '0'
  let balance_rewarded: any = '0'
  let conversion = '0.0001'
  let status = 'none'
  let conversion_att = 'none'
  let base_conversion = '0.0001'
  let reserved = '0'
  let empArr = []
  const userid: string = this.props.auth.getIdToken().payload.sub
      const userPaymentInfo: UserModel = await getInfoUser(this.props.auth.getIdToken(), userid)
      reserved = userPaymentInfo.reserved_coins || '0'
      let conversionArr: any = userPaymentInfo.conversion
      if (!conversionArr || conversionArr === 'none') {
conversionArr = 'none,0.0001'
      }
      conversionArr = conversionArr.split(',')
      conversion_att = conversionArr[0]
      base_conversion = conversionArr[1]
      console.log('conversion_att: ' + conversion_att)
      console.log('conversion_base: ' + base_conversion)
      const usergroup = userPaymentInfo.usergroup
      const email = userPaymentInfo.email
      const duration = userPaymentInfo.timepayment
      const numberofusers = userPaymentInfo.numberofusers
      const companyName = userPaymentInfo.company_name || 'none'
  const users = await getUsers(this.props.auth.getIdToken())
  if (usergroup === 'companies') {
    for (let i = 0; i < users.length; i++) {
      const element = users[i];
      const resultBalance = await getGolden(this.props.auth.getIdToken(), element.id)
      if (element.type === 'company') {
  balance = resultBalance.balance
  status = resultBalance.status
  balance_gained = resultBalance.balance_gained || '0'
  balance_rewarded = resultBalance.balance_reward || '0'
      } else {
        let userObj: RewardEmpModel = {
  balance: resultBalance.balance,
  email: element.email,
  status: resultBalance.status,
  balance_gained: resultBalance.balance_gained || '0',
  balance_rewarded: resultBalance.balance_reward || '0',
  total_rewards: '0'
        }
        const totalRewards = fixRounding((parseFloat(userObj.balance_gained) + parseFloat(userObj.balance_rewarded)), 2).toString()
userObj.total_rewards = totalRewards
        empArr.push(userObj)
      }
  }
            if (duration === 'month') {
              maxPoints = maxPointsMonth
const opMinMonth = fixRounding(((parseInt(maxPointsMonth) * parseFloat(conversion)) * parseInt(numberofusers)), 2)
minimumTokens = opMinMonth.toString()
} else {
  maxPoints = maxPointsYear
const opMinYear = fixRounding(((parseInt(maxPointsYear) * parseFloat(conversion)) * parseInt(numberofusers)), 2)
minimumTokens = opMinYear.toString()
            }
  } else {
    if (usergroup === 'employees' || usergroup === 'guests' || usergroup === 'members') {
      const resultBalance = await getGolden(this.props.auth.getIdToken(), userid)
      balance = resultBalance.balance
    status = resultBalance.status
    balance_gained = resultBalance.balance_gained || '0'
    balance_rewarded = resultBalance.balance_reward || '0'  
  }
  }
this.setState({loading: false, usergroup, companyName, userBalance: balance, employees: empArr, userStatus: status, reservedBalance: reserved, email, numberofusers, minimumTokens, maxPoints, conversion_att, base_conversion, balanceGained: balance_gained, balanceRewarded: balance_rewarded})
} catch (error) {
  this.setState({loading: false})
    console.log(error)
}
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading Golden Coin details...
        </Loader>
      </Grid.Row>
    )
  }

          renderGolden() {
            let infoObj = {
              email: this.state.email,
              company_name: this.state.companyName,
              group: this.state.usergroup
            }
            return (
               <div>
            <HeaderCustom headerType="golden_creation"/>
                <Grid container centered relaxed verticalAlign="middle">
                      <Grid.Row columns={1} centered>
                        <Grid.Column verticalAlign="middle" textAlign="center">
                <GoldenContainerNew infoObj={infoObj} history={this.props.history} token={this.props.auth.getIdToken()} />
                </Grid.Column>
                      </Grid.Row>
                    </Grid>
              </div>
            )
          }

      renderBaseCompany() {
        const ninetyBalance = fixRounding((parseFloat(this.state.userBalance) * 90)/100, 2)
        let minBalance = 0
        if (ninetyBalance < parseFloat(this.state.minimumTokens)) {
minBalance = fixRounding(ninetyBalance + (parseFloat(this.state.minimumTokens) - ninetyBalance) + ((parseFloat(this.state.userBalance) * 10)/100), 2)
        }
        let remainingBalance = 0
        if (this.state.reservedBalance !== this.state.userBalance) {
remainingBalance = fixRounding(parseFloat(this.state.userBalance) - parseFloat(this.state.reservedBalance), 2)
        }
        const ninetyRemainingBalance = fixRounding((remainingBalance * 90)/100, 2)
        let minRemainingBalance = 0
        if (ninetyRemainingBalance < parseFloat(this.state.minimumTokens)) {
minRemainingBalance =  fixRounding(ninetyBalance + (parseFloat(this.state.minimumTokens) - ninetyBalance) + ((parseFloat(this.state.userBalance) * 10)/100), 2)
        }
        let convTax = 0
        if (this.state.conversion_att !== 'none') {
          convTax = fixRounding((1000 * parseFloat(this.state.conversion_att)), 2)
        }  
        return (
          <div>
            <HeaderCustom headerType="golden_status"/>
          <Grid container centered relaxed verticalAlign="middle" columns="equal">
                <Grid.Row columns={1}>
                  <Grid.Column textAlign='center' verticalAlign="middle">
                  <div>
                        <Grid container  centered relaxed verticalAlign="middle" style={{ marginTop: '-0.1em', overflow: 'auto', maxWidth: '90vh', maxHeight: '60vh'}}>
              <Grid.Row columns={1} centered>
                <Grid.Column style={{minWidth: '20em',maxWidth: '33em'}} verticalAlign="middle" textAlign="center">
    <Form style={{ margin: '0 auto' }}>
      <div style={divBadgeStyle}>
      <Image size='medium' centered src={newGoldenBadgeLargeSrc} />
      </div>
      <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Company Total Balance</label>
              <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              {this.state.userBalance} <Popup content='Check Atlanticoin Platform to learn how to use them' trigger={<strong>Golden Coins</strong>} />
              </div>
      </div>
      <div style={divStyle}>
      <Popup content='This balance is used to boost the training results of your employees' trigger={<label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Tokens reserved for Rewards</label>} />
      <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              {this.state.reservedBalance} <Popup content='Check Atlanticoin Platform to learn how to use them' trigger={<strong>Golden Coins</strong>} />
              </div>
      </div>
      <div style={divStyle}>
      <Popup content='These tokens are rewarded by VirtualVIP' trigger={<label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Tokens Rewarded by VirtualVIP</label>} />
      <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              {this.state.balanceRewarded} <Popup content='Check Atlanticoin Platform to learn how to use them' trigger={<strong>Golden Coins</strong>} />
              </div>
      </div>
      {this.state.reservedBalance !== '0' && this.state.base_conversion === '0.00005' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Boost Mode</label>
      <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
  <Popup content='The Conversion rate is at the Base level' trigger={<Label size='huge' color='orange' style={{fontSize: '0.8rem', fontWeight: "bold"}}><strong>BASE</strong></Label>} />
              </div>
              <div style={{fontSize: '0.8rem', fontWeight: "bold", marginTop: '0.5em', color: '#6435c9'}}>Conversion Rate:</div>
              <div style={{
  fontSize: '0.75rem',
  textAlign: 'center',
  border: '0em',
  marginBottom: '0.1em'
}}>
             <strong>1000</strong> - Max VVIP Points for Session = <strong>{convTax}</strong> Golden Coins
              </div>
      </div>}
      {this.state.reservedBalance !== '0' && this.state.base_conversion === '0.0001' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Boost Mode</label>
      <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
  <Popup content='The Conversion rate is at the Top level' trigger={<Label size='huge' color='orange' style={{fontSize: '0.8rem', fontWeight: "bold"}}><strong>TOP</strong></Label>} />
              </div>
              <div style={{fontSize: '0.8rem', fontWeight: "bold", marginTop: '0.5em', color: '#6435c9'}}>Conversion Rate:</div>
              <div style={{
  fontSize: '0.75rem',
  textAlign: 'center',
  border: '0em',
  marginBottom: '0.1em'
}}>
             <strong>1000</strong> - Max VVIP Points for Session = <strong>{convTax}</strong> Golden Coins
              </div>
      </div>}
      {this.state.reservedBalance !== '0' && this.state.base_conversion === '0.0002' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Boost Mode</label>
      <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
  <Popup content='The Conversion rate is at the Max level' trigger={<Label size='huge' color='orange' style={{fontSize: '0.8rem', fontWeight: "bold"}}><strong>MAX</strong></Label>} />
              </div>
              <div style={{fontSize: '0.8rem', fontWeight: "bold", marginTop: '0.5em', color: '#6435c9'}}>Conversion Rate:</div>
              <div style={{
  fontSize: '0.75rem',
  textAlign: 'center',
  border: '0em',
  marginBottom: '0.1em'
}}>
             <strong>1000</strong> - Max VVIP Points for Session = <strong>{convTax}</strong> Golden Coins
              </div>
      </div>}
                        {this.state.numberofusers !== '1' && <div style={divStyle}>
      <Popup content='Login to the Atlanticoin platform' trigger={<Button
                          loading={this.state.loadingbutton}
                          style={{marginBottom: '0.2em'}}
                          color="orange"
                          size="medium"
                          className="action-button"
                          onClick={() => this.openInNewTab(`${digitalGoldUrl}/customer/account/login`)}
                        >
                          Add Tokens
                        </Button>} />
                        
                        {
                minBalance === 0 && this.state.reservedBalance === '0' &&
                  <Popup content='Reward your employees with Golden Coins' trigger={<Button
                          loading={this.state.loadingbutton}
                          color="orange"
                          size="medium"
                          className="action-button"
                          onClick={this.handleBoost}
                        >
                          Boost Performance
                        </Button>} />}
                {
                minBalance !== 0 && this.state.reservedBalance === '0' && 
                  <Popup content={`You need a minimum of ${minBalance} Golden Coins to reward your employees`} trigger={
                    <div>
                  <Button
                          loading={this.state.loadingbutton}
                          color="orange"
                          size="medium"
                          className="action-button"
                          onClick={this.handleBoost}
                          disabled
                        >
                          Boost Performance
                        </Button>
                        </div>
                      } />}
                {
                this.state.reservedBalance !== '0' && minRemainingBalance === 0 &&
                  <Popup content='Reward your employees with Golden Coins' trigger={
                  <Button
                          loading={this.state.loadingbutton}
                          color="orange"
                          size="medium"
                          className="action-button"
                          onClick={this.handleBoost}
                        >
                          Update Boost
                          </Button>
                        } />}
                {
                this.state.reservedBalance !== '0' && minRemainingBalance !== 0 &&
                  <Popup content={`You need a minimum of ${minRemainingBalance} Golden Coins to do a new Boost`} trigger={
                    <div><Button
                          loading={this.state.loadingbutton}                          
                          color="orange"
                          size="medium"
                          className="action-button"
                          onClick={this.handleBoost}
                          disabled
                        >
                          Update Boost
                        </Button>
                        </div>
                        } />}
                        
      </div>}
      {this.state.numberofusers === '1' && <div style={divStyle}>
      <Popup content='Login to the Atlanticoin platform' trigger={<Button
                          loading={this.state.loadingbutton}
                          style={{marginBottom: '0.2em'}}
                          color="orange"
                          size="medium"
                          className="action-button"
                          onClick={() => this.openInNewTab(`${digitalGoldUrl}/customer/account/login`)}
                        >
                          Add Tokens
                        </Button>} />
                        <Popup content={`Activate your Subscription to Boost your Employees!`} trigger={
                    <div>
                  <Button
                          color="orange"
                          size="medium"
                          className="action-button"
                          disabled
                        >
                          Boost Performance
                        </Button>
                        </div>
                      } />
        </div>}
    </Form>
    </Grid.Column>
              </Grid.Row>
              </Grid>
              <Grid container celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", overflow: 'auto', maxWidth: '90vh', maxHeight: '30vh', marginTop: '5em'}}>
              <Grid.Row columns={1}>
                <Grid.Column style={{fontWeight: "bold", fontSize: '0.8rem'}}>Employees Rewards</Grid.Column>
              </Grid.Row>
              <Grid.Row columns={4} style={{minWidth: "35em"}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', padding: '0.2em', overflow: 'auto'}}>Email</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Tokens Rewarded by your Boost</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Tokens Gained by Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Total Gained with VirtualVIP</Grid.Column>
            </Grid.Row>
            {this.state.employees.map((user) => {
                    return (
                      <Grid.Row columns={4} style={{minWidth: "35em"}}>
                        <Grid.Column textAlign='center' style={{fontWeight: "bold", padding: '0.2em', overflow: 'auto'}}>{user.email}</Grid.Column>
                        {user.status !== 'DISABLED' && user.status !== 'WAITING' && <Grid.Column textAlign='center' style={{fontWeight: "bold", padding: '0.2em', overflow: 'auto'}}>{user.balance_gained} <Popup content='Check Atlanticoin Platform to learn how to use them' trigger={<strong>Golden Coins</strong>} /></Grid.Column>}
                        {(user.status === 'DISABLED' || user.status === 'WAITING') && <Grid.Column textAlign='center' style={{fontWeight: "bold", padding: '0.2em', overflow: 'auto'}}>Not Activated Yet</Grid.Column>}
                        {user.status !== 'DISABLED' && user.status !== 'WAITING' && <Grid.Column textAlign='center' style={{fontWeight: "bold", padding: '0.2em', overflow: 'auto'}}>{user.balance_rewarded} <Popup content='Check Atlanticoin Platform to learn how to use them' trigger={<strong>Golden Coins</strong>} /></Grid.Column>}
                        {(user.status === 'DISABLED' || user.status === 'WAITING') && <Grid.Column textAlign='center' style={{fontWeight: "bold", padding: '0.2em', overflow: 'auto'}}>Not Activated Yet</Grid.Column>}
                        {user.status !== 'DISABLED' && user.status !== 'WAITING' && <Grid.Column textAlign='center' style={{fontWeight: "bold", padding: '0.2em', overflow: 'auto'}}>{user.total_rewards} <Popup content='Check Atlanticoin Platform to learn how to use them' trigger={<strong>Golden Coins</strong>} /></Grid.Column>}
                        {(user.status === 'DISABLED' || user.status === 'WAITING') && <Grid.Column textAlign='center' style={{fontWeight: "bold", padding: '0.2em', overflow: 'auto'}}>Not Activated Yet</Grid.Column>}
                      </Grid.Row>
                        )
                                                     
                })}
                {this.state.employees.length === 0 && <Grid.Row column={4} style={{minWidth: "35em"}}>
              <Grid.Column style={{fontWeight: "bold", padding: '0.2em', overflow: 'auto'}}>None Available</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", padding: '0.2em', overflow: 'auto'}}>None Available</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", padding: '0.2em', overflow: 'auto'}}>None Available</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", padding: '0.2em', overflow: 'auto'}}>None Available</Grid.Column>
        </Grid.Row>}
            </Grid>
    </div>
                  </Grid.Column>
                </Grid.Row>
                </Grid>
                </div>
          )
      }

      renderBaseEmployee() {
        const totalRewards = fixRounding((parseFloat(this.state.balanceGained) + parseFloat(this.state.balanceRewarded)), 2).toString()
        return (
          <div>
            <HeaderCustom headerType="golden_status"/>
            <Grid container centered relaxed verticalAlign="middle" columns="equal">
            <Grid.Row columns={1}>
                  <Grid.Column>
                  <div>
                        <Grid container  centered relaxed verticalAlign="middle" style={{marginTop: '-0.1em', maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh'}}>
              <Grid.Row columns={1} centered>
                <Grid.Column style={{minWidth: '20em',maxWidth: '33em'}} verticalAlign="middle" textAlign="center">
    <Form style={{ margin: '0 auto' }}>
      <div style={divBadgeStyle}>
      <Image size='small' centered src={newGoldenBadgeLargeSrc} />
      </div>
      {this.state.usergroup === 'employees' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Tokens Rewarded by your Manager</label>
              <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              {this.state.balanceGained} <Popup content='Check Atlanticoin Platform to learn how to use them' trigger={<strong>Golden Coins</strong>} />
              </div>
              </div>}
              {this.state.usergroup === 'employees' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Tokens Gained by Rewards</label>
              <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              {this.state.balanceRewarded} <Popup content='Check Atlanticoin Platform to learn how to use them' trigger={<strong>Golden Coins</strong>} />
              </div>
              </div>}
              {this.state.usergroup === 'employees' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Total Gained with VirtualVIP</label>
              <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              {totalRewards} <Popup content='Check Atlanticoin Platform to learn how to use them' trigger={<strong>Golden Coins</strong>} />
              </div>
              </div>}
              {this.state.usergroup !== 'employees' && <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Tokens Rewarded by VirtualVIP</label>
              <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
              {totalRewards} <Popup content='Check Atlanticoin Platform to learn how to use them' trigger={<strong>Golden Coins</strong>} />
              </div>
              </div>}
              <div style={divStyle}>
      <Popup content='Login to the Atlanticoin platform' trigger={<Button
                          loading={this.state.loadingbutton}
                          style={{marginBottom: '0.2em'}}
                          color="orange"
                          size="medium"
                          className="action-button"
                          onClick={() => this.openInNewTab(`${digitalGoldUrl}/customer/account/login`)}
                        >
                          Use your Tokens
                        </Button>} />
                        </div>
    </Form>
    </Grid.Column>
              </Grid.Row>
              {this.state.userStatus === 'ENABLED' && this.state.usergroup === 'employees' && <Grid.Row columns={1} centered>
              <Grid.Column verticalAlign="middle" textAlign="center">
                        <Message color='violet' size="small"><Message.Header>Power Up your Training!</Message.Header><p>Reach out your Manager and ask him to Boost your Performance with Golden Coins!</p></Message>
                </Grid.Column>
              </Grid.Row>}
                    </Grid>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                </Grid>
                </div>
          )
      }

      renderBaseExpired() {
        return (
<div>
            <HeaderCustom headerType="golden_status"/>
          <Grid container centered relaxed verticalAlign="middle" columns="equal">
                <Grid.Row columns={1} centered>
                  <Grid.Column verticalAlign="middle" textAlign="center">
                  <Message color='violet' size="large"><Message.Header>Your account is Expired!</Message.Header><p>To use this feature you need to activate your account on Payment section. You can still check your Golden Coin balance on {digitalGoldUrl}</p></Message>
                  </Grid.Column>
                </Grid.Row>
                </Grid>
                </div>
        )
      }

      renderFree() {
        return (
<div>
            <HeaderCustom headerType="golden_status"/>
          <Grid container centered relaxed verticalAlign="middle" columns="equal">
                <Grid.Row columns={1} centered>
                  <Grid.Column verticalAlign="middle" textAlign="center">
                  <Message color='violet' size="large"><Message.Header>Your account is in Free Trial Mode!</Message.Header><p>To use this feature you need to activate your account on Payment section</p></Message>
                  </Grid.Column>
                </Grid.Row>
                </Grid>
                </div>
        )
      }

      renderBusiness() {
        return (
<div>
            <HeaderCustom headerType="golden_status"/>
          <Grid container centered relaxed verticalAlign="middle" columns="equal">
                <Grid.Row columns={1} centered>
                  <Grid.Column verticalAlign="middle" textAlign="center">
                  <Message color='violet' size="large"><Message.Header>You have not a Business Account!</Message.Header><p>This is an exclusive feature for Business Accounts. Check Atlanticoin Platform and create a Virtual Vip Business account if you are interested!</p></Message>
                  </Grid.Column>
                </Grid.Row>
                </Grid>
                </div>
        )
      }

      renderBusinessWaiting() {
        return (
<div>
            <HeaderCustom headerType="golden_status"/>
          <Grid container centered relaxed verticalAlign="middle" columns="equal">
                <Grid.Row columns={1} centered>
                  <Grid.Column verticalAlign="middle" textAlign="center">
                  <Message color='violet' size="large"><Message.Header>Reach out your Manager!</Message.Header><p>He can activate this exclusive Feature and Boost your Training with Golden Coins! Check Atlanticoin Platform if you are interested.</p></Message>
                  </Grid.Column>
                </Grid.Row>
                </Grid>
                </div>
        )
      }

      renderBottomDigitalGold() {
        return (
          <Grid centered relaxed columns='equal' style={{margin: '0.5em'}}>
                        <Grid.Row columns={1} centered>
                          <Grid.Column textAlign="center">
                          <Button
                              color="blue"
                              size="large"
                              className="action-button"
                              onClick={() => this.openInNewTab(digitalGoldUrl)}
                            >
                             Check Atlanticoin Platform!
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                        </Grid>
        )
          }

      renderBase() {
if (this.state.usergroup === 'expired') {
  return this.renderBaseExpired()
} else {
if (this.state.usergroup === 'companies') {
return this.renderBaseCompany()
} else {
return this.renderBaseEmployee()
}
}
      }

  render() {
    if (this.state.loading) {
      return this.renderLoading()
    }
    if (this.state.userStatus === 'DISABLED') {
      return (
        <div>
    {this.renderGolden()}
    
    {this.renderBottomDigitalGold()}
        </div>
      )
    }
    if (this.state.userStatus === 'WAITING') {
      return (
        <div>
    {this.renderBusinessWaiting()}
    
    {bottomGoldAvailable && this.renderBottomDigitalGold()}
        </div>
      )
    }
    return (
      <div>
  {this.renderBase()}
  
  {bottomGoldAvailable && this.renderBottomDigitalGold()}
      </div>
    )
  }
}
