import * as React from 'react'
import { Auth } from '../auth/Auth'
import StripeContainer from './StripeContainer'
import PaypalContainer from './PaypalContainer'
import { checkPayment } from '../api/users-api'
import { PayConfirmModel } from '../types/PayConfirmModel'
import { Grid, Loader } from 'semantic-ui-react'
import { InvoiceModel } from '../types/InvoiceModel'
import StripeBank from './BankContainer'

interface PaymentgatewayProps {
  match: {
    params: {
      infoarray: string
    }
  },
  auth: Auth,
  history: any
}

interface PaymentgatewayState {
  customer_id: string,
  loading: boolean
}

export class Paymentgateway extends React.PureComponent<
PaymentgatewayProps,
PaymentgatewayState
> {

  state: PaymentgatewayState = {
customer_id: 'none',
loading: true
  }

  async componentDidMount() {
    console.log('mounting payment gateway');
    let infoar = this.props.match.params.infoarray.split(',')
    let email = infoar[8]
if (infoar[7] === 'card') {
  try {
  let resultch = await checkPayment(this.props.auth.getIdToken(), email)
  this.setState({customer_id: resultch, loading: false})
  console.log('customerid checked: ' + resultch)
  } catch (error) {
    console.log('error checkpayment payment gateway');
        this.setState({loading: false})
            this.props.history.replace('/payment/error');  
  }
} else {
  this.setState({loading: false})
}
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading payment processors...
        </Loader>
      </Grid.Row>
    )
  }

  renderBase() {
    let infoar = this.props.match.params.infoarray.split(',')
    let method = infoar[7]
    let checked = infoar[3]
    let vault = false
    let type = 'capture'
    let renew = 'no'
    let contract = 'false'
    let upgrade = 'none'
    if (checked === 'true') {
      renew = 'yes'
      if (infoar[13] !== 'none') {
type = 'capture'
vault = false
      }  else {
        type = 'subscription'
        vault = true
      }
    }
    if (infoar[13] !== 'none') {
upgrade = infoar[13]
    }
    let invoiceObj: InvoiceModel
if (infoar[1] === 'companies') {
  contract = infoar[19]
invoiceObj = {
subscriptionType: infoar[14],
subscriptionPrice: infoar[15],
period: infoar[17],
companyName: infoar[18],
type: infoar[16]
}
} else {
  contract = infoar[18]
invoiceObj = {
  subscriptionType: infoar[14],
  subscriptionPrice: infoar[15],
  period: infoar[17],
  type: infoar[16]
  }
}
    console.log('invoice data', invoiceObj)
    const infoObj: PayConfirmModel = {
newexpiration: infoar[0],
renew,
email: infoar[8],
method: infoar[7],
extend: infoar[10],
usergroup: infoar[1],
charge: infoar[2],
oldexpiration: infoar[6],
planid: infoar[11],
duration: infoar[4],
numberofusers: infoar[5],
start_date: infoar[9],
useredit: infoar[12],
invoice_data: invoiceObj
    }
    console.log('payment data', infoObj)
    
    if (method === 'card') {
    return (
      <div>
        <Grid container  centered relaxed verticalAlign="middle">
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
        <StripeContainer infoObj={infoObj} history={this.props.history} billing={this.state.customer_id} token={this.props.auth.getIdToken()} upgrade={upgrade} contract={contract}/>
        </Grid.Column>
              </Grid.Row>            
            </Grid>
      </div>
    )
    } else {
      if (method === 'bank') {
        return (
          <div>
            <Grid container  centered relaxed verticalAlign="middle">
                <Grid.Row columns={1} centered>
                  <Grid.Column verticalAlign="middle" textAlign="center">
  <StripeBank infoObj={infoObj} history={this.props.history} type={type} vault={vault} token={this.props.auth.getIdToken()} upgrade={upgrade} contract={contract}/>
  </Grid.Column>
                </Grid.Row>            
              </Grid>
          </div>
        )
      } else {
        return (
          <div>
            <Grid container  centered relaxed verticalAlign="middle">
                <Grid.Row columns={1} centered>
                  <Grid.Column verticalAlign="middle" textAlign="center">
  <PaypalContainer infoObj={infoObj} history={this.props.history} type={type} vault={vault} token={this.props.auth.getIdToken()} upgrade={upgrade} contract={contract}/>
  </Grid.Column>
                </Grid.Row>            
              </Grid>
          </div>
        )
      }
    }
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading()
    }
    return this.renderBase() 
  }
}
