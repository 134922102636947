/* eslint-disable array-callback-return */
import * as React from 'react'
import { Grid, Button, Header, Form, Loader, Divider, Icon, Message, Label } from 'semantic-ui-react'
import { sendEmail, createCoupon, getCoupons, deleteCoupon, updateCoupons } from '../api/users-api'
import  { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { FileUploader } from './FileUploader'
import { ClipboardCopy } from './ClipboardCopy'
import { CouponFormatted, CouponReq } from '../types/CouponModel'
import { CSVLink } from 'react-csv'
import { CheckElementCoupon } from './CheckElementCoupon'

const divStyle = {
  border: '0.13em solid #6435c9',
  borderRadius: '0.2em',
  padding: '0.6em 0.1em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF'
}

interface CreateCouponProps {
  auth: Auth,
  history: any
}

interface CreateCouponState {
  idCheck: string[],
  idView: string[],
  formInput: string,
  selectCheck: boolean,
  selectClear: boolean,
  keycode: string,
  userIdArray: string,
  loading: boolean,
  coupons: CouponFormatted[],
  stateCreation: boolean,
  loadingbutton: boolean,
  checked: boolean,
  sendDelete: string,
  statusDel: string,
  statusUp: string,
  createSendDelete: string,
  update: boolean,
  reqExpiration: string,
  csvOptions: any,
  checkUpdateNeed: boolean,
  selectType: string
}
const { couponExpiration, vvipUrl } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

export class CreateCoupon extends React.PureComponent<
  CreateCouponProps,
  CreateCouponState
> {
  state: CreateCouponState = {
    idCheck: [],
    idView: [],
    formInput: '',
    selectCheck: false,
    selectClear: true,
    csvOptions: {},
    keycode: '',
    userIdArray: '',
    loading: true,
    coupons: [],
    stateCreation: false,
    loadingbutton: false,
    checked: false,
    sendDelete: 'none',
    statusDel: 'delete process not started yet.',
    statusUp: 'not uploaded yet!',
    createSendDelete: 'none',
    update: false,
    reqExpiration: '',
    checkUpdateNeed: false,
    selectType: 'normal'
  }

  csvLink = React.createRef<HTMLDivElement>()

  handleDownload = () => {
    this.csvLink.current ? this.csvLink.current.click() : alert('Unkwnown error!')
      }

  handleSelectAll = () => {
    let idAll = this.state.idView
    if (this.state.selectCheck) {
      this.setState({ idCheck: idAll, selectCheck: false })
    } else {
    this.setState({ idCheck: idAll, selectCheck: true })
    }
  }

  handleClear = () => {
    if (this.state.selectClear) {
    this.setState({ idCheck: [], selectClear: false })
    } else {
      this.setState({ idCheck: [], selectClear: true }) 
    }
  }

  handleCreateAllUserId = (userIdarray: string[]) => {
    let idCheckNew = userIdarray
      this.setState({ idView: idCheckNew })
  }

  handleDeleteAllUserId = () => {
    this.setState({ idView: [] })
  }

  handleIdCheckPush = (userId: string) => {
    let idCheckNew = this.state.idCheck.concat([userId])
      this.setState({ idCheck: idCheckNew })
      console.log('checkpush: ' + userId + 'idcheck: ' + this.state.idCheck)
  }

  handleIdCheckDelete = (userId: string) => {
    let idCheckNew = this.state.idCheck.filter(
      id => id !== userId
    )
      this.setState({ idCheck: idCheckNew })
      console.log('checkdelete: ' + userId + 'idcheck: ' + this.state.idCheck)
  }

  shouldComponentUpdate(nextProps: CreateCouponProps, nextState: CreateCouponState) {
    if (nextState.idView !== this.state.idView) {return false}
    return true
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.update === this.state.update) {
      let checkUpdateNeed = false   
      let couponArrFormatted: CouponFormatted[] = []
      try {
        const coupons = await getCoupons(this.props.auth.getIdToken())
        for (let index = 0; index < coupons.length; index++) {
          let couponFormatted: CouponFormatted = {
            Code: '',
            exp: '',
            trial: null,
            seller: null,
            creation: null,
            tag: null,
            type: null,
            creationFormatted: 'none'
          }
          console.log('datetime:', coupons[index].exp.S)
          console.log('keycode:', coupons[index].Code.S)
          let dateFormatted = new Date(parseInt(coupons[index].exp.S))
          let dateCreateFormatted = null
          couponFormatted.Code = coupons[index].Code.S
          couponFormatted.exp = dateFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
          const couponVarType = coupons[index].type || {S: 'none'}
  const couponVarTag = coupons[index].tag || {S: '[ none ]'}
  const couponVarTrial = coupons[index].trial || {S: 'none'}
  const couponVarSeller = coupons[index].seller || {S: 'none'}
  const couponVarCreation = coupons[index].creation || {S: '0'}
  let couponVarCreationStamp: any = coupons[index].creation || {S: 'none'}
  couponVarCreationStamp = couponVarCreationStamp.S
  if (coupons[index].creation) {dateCreateFormatted = new Date(parseInt(couponVarCreation.S))} else {checkUpdateNeed = true}
  if (dateCreateFormatted) {if (dateCreateFormatted !== new Date(0)) { couponFormatted.creationFormatted = dateCreateFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
  if (coupons[index].type) {couponFormatted.type = couponVarType.S} else {checkUpdateNeed = true}
  if (coupons[index].trial) {couponFormatted.trial = couponVarTrial.S} else {checkUpdateNeed = true}
  if (coupons[index].seller) {couponFormatted.seller = couponVarSeller.S} else {checkUpdateNeed = true}
  if (coupons[index].tag) {couponFormatted.tag = couponVarTag.S} else {checkUpdateNeed = true}
  if (coupons[index].creation) {couponFormatted.creation = couponVarCreationStamp} else {checkUpdateNeed = true}
          couponArrFormatted.push(couponFormatted)
        }
        if (couponArrFormatted && couponArrFormatted.length > 1) {
          couponArrFormatted.sort((a, b) => {
            return parseInt(b.creation) - parseInt(a.creation)
          })
        }
        const headersCsv = [
          {label: 'TIMESTAMP', key: 'creation'},
          {label: 'KEYCODE', key: 'Code'},
          {label: 'CREATION DATE', key: 'creationFormatted'},
          {label: 'TAG', key: 'tag'},
          {label: 'LINKED SELLER', key: 'seller'},
          {label: 'COUPON EXPIRATION', key: 'exp'},
          {label: 'TRIAL DURATION', key: 'trial'},
          {label: 'TYPE', key: 'type'}
        ]
  this.setState((prevState: any) => ({ coupons: couponArrFormatted, update: !prevState.update, checkUpdateNeed, csvOptions: {
    data: couponArrFormatted,
  headers: headersCsv,
  filename: 'vvip-coupons.csv'
  } }))
    } catch (e) {
      console.log(e)
    }
    }
  }

  async componentDidMount() {
    let couponArrFormatted: any[] = []
    let checkUpdateNeed = false
    this.setState({loading: true, stateCreation: false})
    try {
const coupons = await getCoupons(this.props.auth.getIdToken())
for (let index = 0; index < coupons.length; index++) {
  let couponFormatted: CouponFormatted = {
    Code: '',
    exp: '',
    trial: null,
    seller: null,
    creation: null,
    tag: null,
    type: null,
    creationFormatted: ''
  }
  console.log('datetime:', coupons[index].exp.S)
  console.log('keycode:', coupons[index].Code.S)
  let dateFormatted = new Date(parseInt(coupons[index].exp.S))
  let dateCreateFormatted = null
  couponFormatted.Code = coupons[index].Code.S
  couponFormatted.exp = dateFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
  const couponVarType = coupons[index].type || {S: 'none'}
  const couponVarTag = coupons[index].tag || {S: '[ none ]'}
  const couponVarTrial = coupons[index].trial || {S: 'none'}
  const couponVarSeller = coupons[index].seller || {S: 'none'}
  const couponVarCreation = coupons[index].creation || {S: '0'}
  let couponVarCreationStamp: any = coupons[index].creation || {S: 'none'}
  couponVarCreationStamp = couponVarCreationStamp.S
  if (coupons[index].creation) {dateCreateFormatted = new Date(parseInt(couponVarCreation.S))} else {checkUpdateNeed = true}
  if (dateCreateFormatted) {if (dateCreateFormatted !== new Date(0)) { couponFormatted.creationFormatted = dateCreateFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
  if (coupons[index].type) {couponFormatted.type = couponVarType.S} else {checkUpdateNeed = true}
  if (coupons[index].trial) {couponFormatted.trial = couponVarTrial.S} else {checkUpdateNeed = true}
  if (coupons[index].seller) {couponFormatted.seller = couponVarSeller.S} else {checkUpdateNeed = true}
  if (coupons[index].tag) {couponFormatted.tag = couponVarTag.S} else {checkUpdateNeed = true}
  if (coupons[index].creation) {couponFormatted.creation = couponVarCreationStamp} else {checkUpdateNeed = true}
  couponArrFormatted.push(couponFormatted)
}
if (couponArrFormatted && couponArrFormatted.length > 1) {
  couponArrFormatted.sort((a, b) => {
    return parseInt(b.creation) - parseInt(a.creation)
  })
}
const headersCsv = [
  {label: 'TIMESTAMP', key: 'creation'},
  {label: 'KEYCODE', key: 'Code'},
  {label: 'CREATION DATE', key: 'creationFormatted'},
  {label: 'TAG', key: 'tag'},
  {label: 'LINKED SELLER', key: 'seller'},
  {label: 'COUPON EXPIRATION', key: 'exp'},
  {label: 'TRIAL DURATION', key: 'trial'},
  {label: 'TYPE', key: 'type'}
]

this.setState({ coupons: couponArrFormatted, loading: false, checkUpdateNeed, csvOptions: {
  data: couponArrFormatted,
headers: headersCsv,
filename: 'vvip-coupons.csv'
} })
  } catch (e) {
    this.setState({loading: false})
    console.log(e)
    alert(`Failed to fetch coupons info ` + e)
  }
  }

  handleStartProcess = () => {
    this.setState({statusUp: 'uploading...'})
    }
    handleErrorProcess = (error: string) => {
      alert(error)
      this.setState({statusUp: 'not uploaded yet!'})      
    }
    handleReadSuccess = (result: string) => {
      try {
        const emailArray = result.split(',')
        if (emailArray.length === 0) {
          throw new Error('No content in the file!')
        }
        this.setState({userIdArray: result, statusUp: 'upload successful!'})
      } catch (error) {
        alert('Reading file error! Check correct format and extension.')
        this.setState({statusUp: 'not uploaded yet!'})
      }
    }

    handleUploadSelect = () => {
      this.setState({sendDelete: 'upload'})
    }

    handleDigitSelect = () => {
      this.setState({sendDelete: 'digit'})
    }

handleNo = () => {
  this.setState({stateCreation: false, sendDelete: 'none', statusUp: 'not uploaded yet!', statusDel: 'delete process not started yet.', checked: false, createSendDelete: 'none', idCheck: [],
  idView: [],
  formInput: '',
  selectCheck: false,
  selectClear: true})
}

handleYes = async () => {
  try {
    this.setState({statusDel: 'deleting coupon...', loadingbutton: true})
    await deleteCoupon(
      this.props.auth.getIdToken(),
      this.state.idCheck.toString()
    )
    this.setState({statusDel: 'coupon deleted successfully!', loadingbutton: false})
    alert('Coupon was deleted!')
  } catch (e) {    
    this.setState({statusDel: 'delete process not started yet.', loadingbutton: false})
  alert('Could not delete the coupon!')
  }
}

  handleIdArrayChange = (event: React.ChangeEvent<HTMLInputElement>) => {    
    this.setState({ userIdArray: event.target.value })
  }

  handleReturn = () => {
    this.setState({stateCreation: false, sendDelete: 'none', statusUp: 'not uploaded yet!', statusDel: 'delete process not started yet.', checked: false, createSendDelete: 'none', idCheck: [],
    idView: [],
    formInput: '',
    selectCheck: false,
    selectClear: true})
  }

handleClickDelete = () => {
this.setState({sendDelete: 'delete'})
}

handleClickSend = () => {
  this.setState({sendDelete: 'send'})
}

handleUploadSelectCreate = () => {
  this.setState({createSendDelete: 'upload'})
}

handleDigitSelectCreate = () => {
  this.setState({createSendDelete: 'digit'})
}

handleAdvCreate = () => {
  this.props.history.replace('/advancedcoupon')
}

handleNormalType = () => {
this.setState({selectType: 'normal'})
}

handleSelectTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  this.setState({ selectType: event.target.value })
}

handleSpecialType = () => {
  this.setState({selectType: 'special'})
}

handleGoldenType = () => {
  this.setState({selectType: 'golden'})
}

handleAllType = () => {
  this.setState({selectType: 'all'})
}

handleUpdateCoupons = async () => {
  this.setState({loading: true})
  //UPDATECOUPONS API
  try {
    await updateCoupons(this.props.auth.getIdToken())
    this.setState({loading: false})
  } catch (error) {
    this.setState({loading: false})
    alert('Could not update coupons: ' + error)
  }
}

handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ formInput: event.target.value })
}

  handleCreate = async () => {
    let keycode = ''
    let dateExp = new Date()
    let toDate = new Date()
    if (couponExpiration === 'YEAR') {
      dateExp.setFullYear(dateExp.getFullYear() + 1)
    } else {
      dateExp.setMonth(dateExp.getMonth() + 1)
    }
        let newExp = dateExp.getTime()
        console.log('datexp creation: ' + newExp.toString())
    try {
      this.setState({ loadingbutton: true })
      const couponObj: CouponReq = {
    exp: newExp.toString(),
    type: 'normal',
    trial: '1 hours',
    sellers: 'none',
    creation: toDate.getTime().toString(),
    tag: '[ seller ]',
    number: '1',
    emailcheck: false,
    specialname: 'none'
  }
  const req_exp = dateExp.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
      keycode = await createCoupon(this.props.auth.getIdToken(), couponObj)
      this.setState({ loadingbutton: false, keycode, stateCreation: true, reqExpiration: req_exp })
    } catch (e) {
      this.setState({ loadingbutton: false})
      alert('Could not create the coupon: ' + e)
    }
  }

  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    const emailSubject = 'Virtual Vip Invitation'
    const emailBody = `Congratulations! You can go to ${vvipUrl} and sign up with this coupon: <strong>` + this.state.keycode + `</strong>`
    let newArray = []
    if (this.state.userIdArray) {
      let idArray = this.state.userIdArray.split(',')
      for (let index = 0; index < idArray.length; index++) {
        let element = idArray[index]
        if (element.includes(',')) {
          element = idArray[index].replace(/,/g,'')
        }
        newArray.push(element)
      }
    }
    try {
      if (!newArray.length || !newArray) {
        alert('Email targets should be provided')
        return
      }
      this.setState({ loadingbutton: true })
      await sendEmail(this.props.auth.getIdToken(), 
      newArray.toString(),
emailBody,
emailSubject
      )
      this.setState({ loadingbutton: false })
      alert('Email sent!')
    } catch (e) {
      this.setState({ loadingbutton: false })
      alert('Could not send the email: ' + e)
    }
  }

  handleEmail = async () => {
    const emailSubject = 'Virtual Vip Invitation'
    const emailBody = `Congratulations! You can go to ${vvipUrl} and sign up with this coupon: <strong>` + this.state.idCheck[0] + `</strong>`
    let newArray = []
    if (this.state.userIdArray) {
      let idArray = this.state.userIdArray.split(',')
      for (let index = 0; index < idArray.length; index++) {
        let element = idArray[index]
        if (element.includes(',')) {
          element = idArray[index].replace(/,/g,'')
        }
        newArray.push(element)
      }
    }
    try {
      if (!newArray.length || !newArray) {
        alert('Email targets should be provided')
        return
      }
      for (let index = 0; index < newArray.length; index++) {
        const element = newArray[index];
        if (element === 'redemption' || element === 'upload' || element === 'digit') {
          newArray.splice(index, 1)
        }
      }
      this.state.sendDelete === 'none' ? newArray.push(this.state.createSendDelete) : newArray.push(this.state.sendDelete)
      this.setState({ loadingbutton: true })
      await sendEmail(this.props.auth.getIdToken(), 
      newArray.toString(),
emailBody,
emailSubject
      )
      this.setState({ loadingbutton: false })
      alert('Email sent!')
    } catch (e) {
      this.setState({ loadingbutton: false })
      alert('Could not send the email: ' + e)
    }
  }

  renderAdminUsersList() {
    let usersState = 'zero'
    if (this.state.idCheck.length > 1) {
      usersState = 'many'
    }
    if (this.state.idCheck.length === 1) {
      usersState = 'one'
    }
    switch (usersState) {
      case 'zero':
        return this.renderUsersZero()
      case 'many':
        return this.renderUsersMany()
      case 'one':
        return this.renderUsersOne()
      default:
        return this.renderUsersZero()
    }
  }

  renderUsersMany() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    return (
      <div>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={4} centered>
            <Grid.Column textAlign="center">
              {!this.state.checkUpdateNeed && <Button
                color="violet"
                size="small"
                disabled
                className="add-button"
                onClick={this.handleUpdateCoupons}
              >
                Update Old Coupons
              </Button>}
              {this.state.checkUpdateNeed && <Button
                color="violet"
                size="small"
                className="add-button"
                onClick={this.handleUpdateCoupons}
              >
                Update Old Coupons
              </Button>}
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button color='violet' size='medium' onClick={this.handleClickDelete}>
Delete
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button disabled color='violet' size='medium' onClick={this.handleClickSend}>
Send
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">            
            <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Select Coupon Type</label>
      <select value={this.state.selectType} onChange={this.handleSelectTypeChange}
           style={{width: '100%', margin: 'auto'}} >
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="all">All</option>
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="normal">Normal</option>
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="special">Special</option>
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="golden">Golden</option>
            </select>
      </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={8} style={{minWidth: '72em'}}>
        <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Keycode</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Tag</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Trial Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Seller</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Creation Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.coupons.map((coupon) => {
            if (!this.state.formInput) {
              if (this.state.selectType === coupon.type || this.state.selectType === 'all') {
              idArray.push(coupon.Code)
              if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                this.handleCreateAllUserId(idArray)
             }
              return (
                <CheckElementCoupon
                  coupon={coupon}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={coupon.Code}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
            )
             } else {
              if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                this.handleCreateAllUserId(idArray)
             }
             }            
            } else {
              if (coupon.seller !== 'none') {
                if ((coupon.seller.toLowerCase().includes(this.state.formInput.toLowerCase()) || coupon.tag.toLowerCase().includes(this.state.formInput.toLowerCase())) && (this.state.selectType === coupon.type || this.state.selectType === 'all')) {
                  idArray.push(coupon.Code)
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementCoupon
                  coupon={coupon}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={coupon.Code}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
              } else {
                if (coupon.tag.toLowerCase().includes(this.state.formInput.toLowerCase()) && (this.state.selectType === coupon.type || this.state.selectType === 'all')) {
                  idArray.push(coupon.Code)
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementCoupon
                  coupon={coupon}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={coupon.Code}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
              }              
            }            
          })}
          {this.state.coupons.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersZero() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    return (
      <div>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={4} centered>
            <Grid.Column textAlign="center">
              {!this.state.checkUpdateNeed && <Button
                color="violet"
                size="small"
                disabled
                className="add-button"
                onClick={this.handleUpdateCoupons}
              >
                Update Old Coupons
              </Button>}
              {this.state.checkUpdateNeed && <Button
                color="violet"
                size="small"
                className="add-button"
                onClick={this.handleUpdateCoupons}
              >
                Update Old Coupons
              </Button>}
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button disabled color='violet' size='medium' onClick={this.handleClickDelete}>
Delete
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button disabled color='violet' size='medium' onClick={this.handleClickSend}>
Send
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">            
            <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Select Coupon Type</label>
      <select value={this.state.selectType} onChange={this.handleSelectTypeChange}
           style={{width: '100%', margin: 'auto'}} >
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="all">All</option>
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="normal">Normal</option>
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="special">Special</option>
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="golden">Golden</option>
            </select>
      </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={8} style={{minWidth: '72em'}}>
        <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Keycode</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Tag</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Trial Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Seller</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Creation Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.coupons.map((coupon) => {
            if (!this.state.formInput) {
              if (this.state.selectType === coupon.type || this.state.selectType === 'all') {
              idArray.push(coupon.Code)
              if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                this.handleCreateAllUserId(idArray)
             }
              return (
                <CheckElementCoupon
                  coupon={coupon}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={coupon.Code}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
            )
             } else {
              if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                this.handleCreateAllUserId(idArray)
             }
             }            
            } else {
              if (coupon.seller !== 'none') {
                if ((coupon.seller.toLowerCase().includes(this.state.formInput.toLowerCase()) || coupon.tag.toLowerCase().includes(this.state.formInput.toLowerCase())) && (this.state.selectType === coupon.type || this.state.selectType === 'all')) {
                  idArray.push(coupon.Code)
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementCoupon
                  coupon={coupon}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={coupon.Code}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
              } else {
                if (coupon.tag.toLowerCase().includes(this.state.formInput.toLowerCase()) && (this.state.selectType === coupon.type || this.state.selectType === 'all')) {
                  idArray.push(coupon.Code)
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementCoupon
                  coupon={coupon}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={coupon.Code}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
              }              
            }            
          })}
          {this.state.coupons.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersOne() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    return (
      <div>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={4} centered>
            <Grid.Column textAlign="center">
              {!this.state.checkUpdateNeed && <Button
                color="violet"
                size="small"
                disabled
                className="add-button"
                onClick={this.handleUpdateCoupons}
              >
                Update Old Coupons
              </Button>}
              {this.state.checkUpdateNeed && <Button
                color="violet"
                size="small"
                className="add-button"
                onClick={this.handleUpdateCoupons}
              >
                Update Old Coupons
              </Button>}
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button color='violet' size='medium' onClick={this.handleClickDelete}>
Delete
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button color='violet' size='medium' onClick={this.handleClickSend}>
Send
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">            
            <div style={divStyle}>
      <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Select Coupon Type</label>
      <select value={this.state.selectType} onChange={this.handleSelectTypeChange}
           style={{width: '100%', margin: 'auto'}} >
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="all">All</option>
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="normal">Normal</option>
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="special">Special</option>
<option style={{
  fontSize: '19px',
  textAlign: 'center',
  border: '0em'
}} value="golden">Golden</option>
            </select>
      </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={8} style={{minWidth: '72em'}}>
        <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Type</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Keycode</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Tag</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Trial Duration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Seller</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Expiration</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Creation Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>select</Grid.Column>
          </Grid.Row>
          {this.state.coupons.map((coupon) => {
            if (!this.state.formInput) {
              if (this.state.selectType === coupon.type || this.state.selectType === 'all') {
              idArray.push(coupon.Code)
              if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                this.handleCreateAllUserId(idArray)
             }
              return (
                <CheckElementCoupon
                  coupon={coupon}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={coupon.Code}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
            )
             } else {
              if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                this.handleCreateAllUserId(idArray)
             }
             }            
            } else {
              if (coupon.seller !== 'none') {
                if ((coupon.seller.toLowerCase().includes(this.state.formInput.toLowerCase()) || coupon.tag.toLowerCase().includes(this.state.formInput.toLowerCase())) && (this.state.selectType === coupon.type || this.state.selectType === 'all')) {
                  idArray.push(coupon.Code)
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementCoupon
                  coupon={coupon}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={coupon.Code}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
              } else {
                if (coupon.tag.toLowerCase().includes(this.state.formInput.toLowerCase()) && (this.state.selectType === coupon.type || this.state.selectType === 'all')) {
                  idArray.push(coupon.Code)
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementCoupon
                  coupon={coupon}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={coupon.Code}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (coupon.Code === this.state.coupons[this.state.coupons.length - 1].Code) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
              }              
            }            
          })}
          {this.state.coupons.length === 0 && <Grid.Row column={7} style={{minWidth: '72em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading Coupons
        </Loader>
      </Grid.Row>
    )
  }

  renderCreation() {
    if (this.state.stateCreation) {
      return (      
        <div>
          <Grid centered relaxed columns='equal'>
            <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center"><Header as='h2' icon color='violet'>
    <Icon name='sign language' />
    Coupon creation Completed
  </Header></Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
                <ClipboardCopy copyText={this.state.keycode} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} centered>
          <Grid.Column textAlign='center'>
          <Message size='large' color='violet'>
          <Message.Header>Creation Successfull!</Message.Header>
          <p>This Coupon doesn't activate a free Trial. The customer has to pay in order to start training sessions.</p>
          <p>This Coupon will expire on {this.state.reqExpiration}.</p>
          </Message>
</Grid.Column>
          </Grid.Row>
            <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
              {this.state.createSendDelete === 'none' && 
              <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1} centered style={{marginBottom: '-10px'}}>
                  <Grid.Column textAlign='center'>
                    <Message size='large' color='violet'>
                    <Message.Header>
                      Email Targets
                    </Message.Header>
                    <p>
Choose your Input Method
                    </p>
                    </Message>
                    </Grid.Column>
                </Grid.Row>
            <Grid.Row columns={6}>
            <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
              <Grid.Column textAlign="center">
              <Button
                color="violet"
                  size="large"
                  className="select-button"
                  onClick={this.handleUploadSelectCreate}
                >
                  Upload a List
                </Button>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Button
                color="violet"
                  size="large"
                  className="select-button"
                  onClick={this.handleDigitSelectCreate}
                >
                  Insert Manually
                </Button>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
            </Grid.Row>
            </Grid>}
          {this.state.createSendDelete === 'digit' && <Form onSubmit={this.handleSubmit}>
            <Form.Field>
            <label style={{fontWeight: "bold", color: '#f2711c'}}>Email Targets</label>
            <input
              placeholder="email1,email2,email3..."
              value={this.state.userIdArray}
              onChange={this.handleIdArrayChange}
            />
            </Form.Field>
            {this.renderButton()}
          </Form>}
          {this.state.createSendDelete === 'upload' && <Form onSubmit={this.handleSubmit}>         
            <Form.Field>              
            <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1} centered>            
          <Grid.Column textAlign='center'>
          <Message size='small' color='violet'><Message.Header>Upload your Email Targets</Message.Header><p>The file must be .txt and the format has to be: emailaddress1,emailaddress2,emailaddress3,etc...</p></Message>
              </Grid.Column>
            </Grid.Row>
          <Grid.Row columns={1} centered style={{marginBottom: '-15px', marginTop: '-15px'}}>
          <Grid.Column textAlign="center">
              <Icon color='orange' style={{marginTop: '-8px', marginBottom: '-8px'}} name='angle double down' size='big'/>
              </Grid.Column>              
            </Grid.Row>
                <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
                <FileUploader color={'violet'} onStartProcess={this.handleStartProcess} onError={this.handleErrorProcess} onReadSuccess={this.handleReadSuccess} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} centered style={{marginTop: '-8px'}}>
              <Grid.Column textAlign='center'>
              <Message size='mini' compact color='violet'><Message.Header>Status</Message.Header><p>{this.state.statusUp}</p></Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
            </Form.Field>
            {this.renderButton()}
          </Form>}
              </Grid.Column>
            </Grid.Row>
            </Grid>
            <Divider />
            {this.renderReturnButton()}
        </div>
      )
    } else {
      return (
        <div>
          {this.state.sendDelete === 'none' && <Grid centered relaxed columns='equal'>
            <Grid.Row columns={2} centered>
              <Grid.Column textAlign="center">
              <Button color="orange" size="medium" loading={this.state.loadingbutton} onClick={this.handleCreate}>
        Quick Creation
      </Button>
              </Grid.Column>
              <Grid.Column textAlign="center">
              <Button color="orange" size="medium" loading={this.state.loadingbutton} onClick={this.handleAdvCreate}>
        Advanced Creation
      </Button>
              </Grid.Column>
            </Grid.Row>
            </Grid>}
            {(this.state.sendDelete === 'digit' || this.state.sendDelete === 'upload') && <Grid centered relaxed columns='equal'>
            <Grid.Row columns={2}>
              <Grid.Column textAlign="center">
              <Button color="orange" size="big" loading={this.state.loadingbutton} onClick={this.handleEmail}>
        Send
      </Button>
              </Grid.Column>
              <Grid.Column textAlign="center">
              <Button color="orange" size="big" onClick={this.handleReturn}>
        Return
      </Button>
              </Grid.Column>
            </Grid.Row>
            </Grid>}
            {(this.state.sendDelete === 'delete' || this.state.sendDelete === 'send') && <Grid centered relaxed columns='equal'>
            <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
              <Button color="orange" size="big" onClick={this.handleReturn}>
        Return
      </Button>
              </Grid.Column>
            </Grid.Row>
            </Grid>}
        </div>
      )
    }       
  }

  renderButton() {
    return (
      <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center"><Button color="orange" size="big" loading={this.state.loadingbutton} type="submit">
        Send Email
      </Button></Grid.Column>
          </Grid.Row>      
      </Grid>
    )
  }

  renderReturnButton() {
    return (
      <Grid centered relaxed columns='equal'>
        <Grid.Row columns={3} centered>
            <Grid.Column />
            <Grid.Column textAlign="center" verticalAlign="middle">
            <Button color="orange" fluid loading={this.state.loadingbutton} onClick={this.handleReturn}>
        Return
      </Button>
      </Grid.Column>
      <Grid.Column />
          </Grid.Row>
      </Grid>
    )
  }



  renderCouponList() {
    return (
      <div>
          <HeaderCustom headerType="coupon_status"/>
          {this.state.sendDelete === 'none' && this.renderAdminUsersList()}
              {this.state.sendDelete === 'delete' && <Grid centered relaxed columns='equal' style={{marginTop: '8px'}}>
              {this.state.statusDel !== 'coupon deleted successfully!' && <Grid.Row columns={1} centered>
                  <Grid.Column textAlign='center'>
                    <Message size='big' warning><Message.Header>Are you sure?</Message.Header><p>Your Coupon will be deleted permanently</p></Message>
                  </Grid.Column>
                </Grid.Row>}
          {this.state.statusDel === 'delete process not started yet.' && <Grid.Row columns={6}>
            <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
            <Grid.Column textAlign="center">
            <Button color='violet' size='large' onClick={this.handleYes}>
Yes
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button color='violet' size='large' onClick={this.handleNo}>
No
            </Button>
            </Grid.Column>
            <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>}
          {this.state.statusDel === 'deleting coupon...' && <Grid.Row columns={6}>
            <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
            <Grid.Column textAlign="center">
            <Button disabled color='violet' size='large' onClick={this.handleYes}>
Yes
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button disabled color='violet' size='large' onClick={this.handleNo}>
No
            </Button>
            </Grid.Column>
            <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>}
          {this.state.statusDel === 'coupon deleted successfully!' && <Grid.Row centered columns={1}>
          <Grid.Column textAlign='center'>
          <Message size='big' color='violet'><Message.Header>Process Completed!</Message.Header><p>Your Coupon was deleted. You can return to your Coupon List</p></Message>
          </Grid.Column>
            </Grid.Row>}
          <Grid.Row columns={1} centered>
                  <Grid.Column textAlign='center'>
                    <Message size='tiny' compact color='violet'><Message.Header>STATUS</Message.Header><p>{this.state.statusDel}</p></Message>
                    </Grid.Column>
                </Grid.Row>
          </Grid>}
          {this.state.sendDelete === 'upload' && <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1} centered>            
          <Grid.Column textAlign='center'>
          <Message size='small' color='violet'><Message.Header>Upload your Email Targets</Message.Header><p>The file must be .txt and the format has to be: emailaddress1,emailaddress2,emailaddress3,etc...</p></Message>
              </Grid.Column>
            </Grid.Row>
          <Grid.Row columns={1} centered style={{marginBottom: '-15px', marginTop: '-15px'}}>
          <Grid.Column textAlign="center">
              <Icon color='orange' style={{marginTop: '-8px', marginBottom: '-8px'}} name='angle double down' size='big'/>
              </Grid.Column>              
            </Grid.Row>
                <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
                <FileUploader color={'violet'} onStartProcess={this.handleStartProcess} onError={this.handleErrorProcess} onReadSuccess={this.handleReadSuccess} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} centered style={{marginTop: '-8px'}}>
              <Grid.Column textAlign='center'>
              <Message size='mini' compact color='violet'><Message.Header>Status</Message.Header><p>{this.state.statusUp}</p></Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>}
          {this.state.sendDelete === 'digit' && <Grid centered relaxed columns='equal'>
          <Grid.Row columns={1} centered style={{marginTop: '7px', marginBottom: '-22px;'}}>
                  <Grid.Column textAlign='center'>
                  <Label size='large' color='violet' pointing='below'>Insert your Email List</Label>
                  </Grid.Column>
                </Grid.Row>
          <Grid.Row columns={1} centered>
            <Grid.Column textAlign="center">
            <Form>         
            <Form.Field>
            <label style={{fontWeight: "bold", color: '#f2711c'}}>Email Targets</label>
            <input
              placeholder="email1,email2,email3..."
              value={this.state.userIdArray}
              onChange={this.handleIdArrayChange}
            />
            </Form.Field>
          </Form>
            </Grid.Column>
          </Grid.Row>
          </Grid>}
          {this.state.sendDelete === 'send' && <Grid centered relaxed columns='equal' style={{marginTop: '2px', marginBottom: '1px'}}>
          <Grid.Row columns={1} centered style={{marginBottom: '-10px'}}>
                  <Grid.Column textAlign='center'>
                    <Message size='large' color='violet'>
                    <Message.Header>
                      Email Targets
                    </Message.Header>
                    <p>
Choose your Input Method
                    </p>
                    </Message>
                    </Grid.Column>
                </Grid.Row>
            <Grid.Row columns={6}>
              <Grid.Column></Grid.Column>
              <Grid.Column></Grid.Column>
              <Grid.Column textAlign="center">
              <Button
                color="violet"
                  size="large"
                  className="select-button"
                  onClick={this.handleUploadSelect}
                >
                  Upload a List
                </Button>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Button
                color="violet"
                  size="large"
                  className="select-button"
                  onClick={this.handleDigitSelect}
                >
                  Insert Manually
                </Button>
              </Grid.Column>
              <Grid.Column></Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
            </Grid>}
              </div>
    )
  }

  renderCompletePage() {
    if (this.state.stateCreation) {
return this.renderCreation()
    } else {
return (
  <div>
{this.renderCouponList()}
  
  {this.renderCreation()}
  </div>
)
    }
  }

render() {
  if (this.state.loading) {
    return this.renderLoading()
  }
  return (
    <Grid centered relaxed columns='equal'>
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
                    {this.renderCompletePage()}                               
                </Grid.Column>
              </Grid.Row>            
            </Grid>
  )
}

}