/* eslint-disable array-callback-return */
import React, {useState} from "react";
import { Button, Popup, Form, Grid, Label } from 'semantic-ui-react'
import { createReward } from '../api/users-api'
import { RewardsTargetList } from './RewardsTargetList'

const { rewardsConfig } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

const inputStyle = {
  fontSize: '0.8rem',
  fontSmoothing: 'antialiased',
  ':-webkit-autofill': {
    color: '#b6b6b3',
  },
  '::placeholder': {
    color: '#b6b6b3',
  },
  textAlign: 'center',
  border: '0em',
  fontWeight: "bold"
}

const divStyle = {
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  padding: '0.6em 0.1em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF'
}

const divBadgeStyle = {
  border: '0.1em solid #6435c9',
  borderRadius: '0.2em',
  margin: '0.1em',
  backgroundColor: '#FFFFFF',
  marginBottom: '0.2em'
}

function fixRounding(value, precision) {
  let power = Math.pow(10, precision || 0)
  return Math.round(value * power) / power
}

function dateIsValid(date) {
  return date instanceof Date && !isNaN(date)
}

export const RewardContainerNew = (props) => {
  const [loading, setLoading] = useState(false);
  const [countfirstlevelfirst, setCountfirstlevelfirst] = useState(rewardsConfig.first_level_first_prize)
  const [countfirstlevelsecond, setCountfirstlevelsecond] = useState(rewardsConfig.first_level_second_prize)
  const [countfirstlevelthird, setCountfirstlevelthird] = useState(rewardsConfig.first_level_third_prize)
  const [countsecondlevelfirst, setCountsecondlevelfirst] = useState(rewardsConfig.second_level_first_prize)
  const [countsecondlevelsecond, setCountsecondlevelsecond] = useState(rewardsConfig.second_level_second_prize)
  const [countsecondlevelthird, setCountsecondlevelthird] = useState(rewardsConfig.second_level_third_prize)
  const [countthirdlevelfirst, setCountthirdlevelfirst] = useState(rewardsConfig.third_level_first_prize)
  const [countthirdlevelsecond, setCountthirdlevelsecond] = useState(rewardsConfig.third_level_second_prize)
  const [countthirdlevelthird, setCountthirdlevelthird] = useState(rewardsConfig.third_level_third_prize)
  const [countfourthlevelfirst, setCountfourthlevelfirst] = useState(rewardsConfig.fourth_level_first_prize)
  const [countfourthlevelsecond, setCountfourthlevelsecond] = useState(rewardsConfig.fourth_level_second_prize)
  const [countfourthlevelthird, setCountfourthlevelthird] = useState(rewardsConfig.fourth_level_third_prize)
  const [countfifthlevelfirst, setCountfifthlevelfirst] = useState(rewardsConfig.fifth_level_first_prize)
  const [countfifthlevelsecond, setCountfifthlevelsecond] = useState(rewardsConfig.fifth_level_second_prize)
  const [countfifthlevelthird, setCountfifthlevelthird] = useState(rewardsConfig.fifth_level_third_prize)
  const [countsixthlevelfirst, setCountsixthlevelfirst] = useState(rewardsConfig.sixth_level_first_prize)
  const [countsixthlevelsecond, setCountsixthlevelsecond] = useState(rewardsConfig.sixth_level_second_prize)
  const [countsixthlevelthird, setCountsixthlevelthird] = useState(rewardsConfig.sixth_level_third_prize)
  const [countseventhlevelfirst, setCountseventhlevelfirst] = useState(rewardsConfig.seventh_level_first_prize)
  const [countseventhlevelsecond, setCountseventhlevelsecond] = useState(rewardsConfig.seventh_level_second_prize)
  const [countseventhlevelthird, setCountseventhlevelthird] = useState(rewardsConfig.seventh_level_third_prize)
  const [counteighthlevelfirst, setCounteighthlevelfirst] = useState(rewardsConfig.eighth_level_first_prize)
  const [counteighthlevelsecond, setCounteighthlevelsecond] = useState(rewardsConfig.eighth_level_second_prize)
  const [counteighthlevelthird, setCounteighthlevelthird] = useState(rewardsConfig.eighth_level_third_prize)
  const [countfirst, setCountfirst] = useState(rewardsConfig.max_session_first_prize);
  const [countsecond, setCountsecond] = useState(rewardsConfig.max_session_second_prize);
  const [countthird, setCountthird] = useState(rewardsConfig.max_session_third_prize);
  const [tag, setTag] = useState('')
  const [open, setOpen] = useState(false)
  const [startdate, setStartdate] = useState('')
  const [enddate, setEnddate] = useState('')
  const [nowdate, setNowdate] = useState(true)
  const [neverdate, setNeverdate] = useState(true)

  const handleReturn = () => {
    props.history.replace(`/rewards`)
  }

  const handleTargets = () => {
    setOpen(true)
  }

  const handleNo = () => {
    setOpen(false)
  }

  const handleYes = async (idcheck) => {
    setLoading(true)
    try {
      let startdateSplitted = startdate
    let enddateSplitted = enddate
      if (!startdateSplitted) {
        const todate = new Date()
        startdateSplitted = todate.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
      }
      if (!enddateSplitted) {
        const todate = new Date()
              todate.setFullYear(new Date().getFullYear() + 10)
        enddateSplitted = todate.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
      }
    startdateSplitted = startdateSplitted.split("/")
    enddateSplitted = enddateSplitted.split("/")
    let startdateFormatted = ''
    let enddateFormatted = ''
    try {
      startdateFormatted = new Date( parseInt(startdateSplitted[2], 10), parseInt(startdateSplitted[1], 10) - 1, parseInt(startdateSplitted[0], 10)).getTime()
    enddateFormatted = new Date( parseInt(enddateSplitted[2], 10), parseInt(enddateSplitted[1], 10) - 1, parseInt(enddateSplitted[0], 10)).getTime()
    } catch (error) {
      setLoading(false)
      setOpen(false)
      alert('Please set a Date in DD/MM/YYYY format')
      return
    }
    const checkStartInvalid = dateIsValid(new Date(startdateFormatted))
    const checkEndInvalid = dateIsValid(new Date(enddateFormatted))
    if (!checkStartInvalid || !checkEndInvalid) {
      setLoading(false)
      setOpen(false)
      alert('Please set a Date in DD/MM/YYYY format')
      return
    }
    if (!idcheck || idcheck.length === 0) {
      setLoading(false)
      setOpen(false)
      alert('Please set some targets for your rewards')
      return
    }
    let ids = idcheck.toString()
    const rewardsFirstArr = [countfirstlevelfirst,countsecondlevelfirst,countthirdlevelfirst,countfourthlevelfirst,countfifthlevelfirst,countsixthlevelfirst,countseventhlevelfirst,counteighthlevelfirst]
    const rewardsSecondArr = [countfirstlevelsecond,countsecondlevelsecond,countthirdlevelsecond,countfourthlevelsecond,countfifthlevelsecond,countsixthlevelsecond,countseventhlevelsecond,counteighthlevelsecond]
    const rewardsThirdArr = [countfirstlevelthird,countsecondlevelthird,countthirdlevelthird,countfourthlevelthird,countfifthlevelthird,countsixthlevelthird,countseventhlevelthird,counteighthlevelthird]
    const rewardObj = {
      start_date: startdateFormatted.toString(),
      end_date: enddateFormatted.toString(),
      targets: ids,
rewards_first: rewardsFirstArr.toString(),
rewards_second: rewardsSecondArr.toString(),
rewards_third: rewardsThirdArr.toString(),
sessions_first: countfirst.toString(),
sessions_second: countsecond.toString(),
sessions_third: countthird.toString(),
tag: tag
    }
    if (!tag) {
      setLoading(false)
      setOpen(false)
      alert('Please describe your coupon with a Tag!')
      return
    }
    if (rewardObj.tag.length <= 1) {
      setLoading(false)
      setOpen(false)
      alert('Please describe your coupon with a Tag!')
      return
    }    
    await createReward(props.auth.getIdToken(), rewardObj)
    setLoading(false)
    setOpen(false)
    props.history.replace(`/rewardconfirmation`)
return
  } catch (error) {
  setLoading(false)
  setOpen(false)
  alert('Could not create the group of Rewards!')
  return 
    }
  }

  const handleUnlimited = () => {
    setCountthird(20)
  }

  const handleClickSubtractFirstLevelFirst = () => {
    if (countfirstlevelfirst > 1) {
      setCountfirstlevelfirst(countfirstlevelfirst - 1.00)
    }
    }
    
      const handleClickFirstLevelFirst = (event) => {
        let valueNumb = fixRounding(event.target.valueAsNumber, 2)
        setCountfirstlevelfirst(valueNumb)              
      }
    
      const handleClickAddFirstLevelFirst = () => {
        let max = 999
    if (countfirstlevelfirst < max) {
    setCountfirstlevelfirst(countfirstlevelfirst + 5.00)
    }
      }

      const handleClickSubtractFirstLevelSecond = () => {
        if (countfirstlevelsecond > 1) {
          setCountfirstlevelsecond(countfirstlevelsecond - 1.00)
        }
        }
        
          const handleClickFirstLevelSecond = (event) => {
            let valueNumb = fixRounding(event.target.valueAsNumber, 2)
            setCountfirstlevelsecond(valueNumb)                
          }
        
          const handleClickAddFirstLevelSecond = () => {
            let max = 999
        if (countfirstlevelsecond < max) {
        setCountfirstlevelsecond(countfirstlevelsecond + 2.50)
        }
          }

          const handleClickSubtractFirstLevelThird = () => {
            if (countfirstlevelthird > 1) {
              setCountfirstlevelthird(countfirstlevelthird - 1.00)
            }
            }
            
              const handleClickFirstLevelThird = (event) => {
                let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                setCountfirstlevelthird(valueNumb)                
              }
            
              const handleClickAddFirstLevelThird = () => {
                let max = 999
            if (countfirstlevelthird < max) {
            setCountfirstlevelthird(countfirstlevelthird + 1.00)
            }
              }

              const handleClickSubtractSecondLevelFirst = () => {
                if (countsecondlevelfirst > 1) {
                  setCountsecondlevelfirst(countsecondlevelfirst - 1.00)
                }
                }
                
                  const handleClickSecondLevelFirst = (event) => {
                    let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                    setCountsecondlevelfirst(valueNumb)              
                  }
                
                  const handleClickAddSecondLevelFirst = () => {
                    let max = 999
                if (countsecondlevelfirst < max) {
                setCountsecondlevelfirst(countsecondlevelfirst + 5.00)
                }
                  }
              
                  const handleClickSubtractSecondLevelSecond = () => {
                    if (countsecondlevelsecond > 1) {
                      setCountsecondlevelsecond(countsecondlevelsecond - 1.00)
                    }
                    }
                    
                      const handleClickSecondLevelSecond = (event) => {
                        let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                        setCountsecondlevelsecond(valueNumb)                
                      }
                    
                      const handleClickAddSecondLevelSecond = () => {
                        let max = 999
                    if (countsecondlevelsecond < max) {
                    setCountsecondlevelsecond(countsecondlevelsecond + 2.50)
                    }
                      }
              
                      const handleClickSubtractSecondLevelThird = () => {
                        if (countsecondlevelthird > 1) {
                          setCountsecondlevelthird(countsecondlevelthird - 1.00)
                        }
                        }
                        
                          const handleClickSecondLevelThird = (event) => {
                            let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                            setCountsecondlevelthird(valueNumb)                
                          }
                        
                          const handleClickAddSecondLevelThird = () => {
                            let max = 999
                        if (countsecondlevelthird < max) {
                        setCountsecondlevelthird(countsecondlevelthird + 1.00)
                        }
                          }

                          const handleClickSubtractThirdLevelFirst = () => {
                            if (countthirdlevelfirst > 1) {
                              setCountthirdlevelfirst(countthirdlevelfirst - 1.00)
                            }
                            }
                            
                              const handleClickThirdLevelFirst = (event) => {
                                let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                setCountthirdlevelfirst(valueNumb)              
                              }
                            
                              const handleClickAddThirdLevelFirst = () => {
                                let max = 999
                            if (countthirdlevelfirst < max) {
                            setCountthirdlevelfirst(countthirdlevelfirst + 5.00)
                            }
                              }
                          
                              const handleClickSubtractThirdLevelSecond = () => {
                                if (countthirdlevelsecond > 1) {
                                  setCountthirdlevelsecond(countthirdlevelsecond - 1.00)
                                }
                                }
                                
                                  const handleClickThirdLevelSecond = (event) => {
                                    let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                    setCountthirdlevelsecond(valueNumb)                
                                  }
                                
                                  const handleClickAddThirdLevelSecond = () => {
                                    let max = 999
                                if (countthirdlevelsecond < max) {
                                setCountthirdlevelsecond(countthirdlevelsecond + 2.50)
                                }
                                  }
                          
                                  const handleClickSubtractThirdLevelThird = () => {
                                    if (countthirdlevelthird > 1) {
                                      setCountthirdlevelthird(countthirdlevelthird - 1.00)
                                    }
                                    }
                                    
                                      const handleClickThirdLevelThird = (event) => {
                                        let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                        setCountthirdlevelthird(valueNumb)                
                                      }
                                    
                                      const handleClickAddThirdLevelThird = () => {
                                        let max = 999
                                    if (countthirdlevelthird < max) {
                                    setCountthirdlevelthird(countthirdlevelthird + 1.00)
                                    }
                                      }

                                      const handleClickSubtractFourthLevelFirst = () => {
                                        if (countfourthlevelfirst > 1) {
                                          setCountfourthlevelfirst(countfourthlevelfirst - 1.00)
                                        }
                                        }
                                        
                                          const handleClickFourthLevelFirst = (event) => {
                                            let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                            setCountfourthlevelfirst(valueNumb)              
                                          }
                                        
                                          const handleClickAddFourthLevelFirst = () => {
                                            let max = 999
                                        if (countfourthlevelfirst < max) {
                                        setCountfourthlevelfirst(countfourthlevelfirst + 5.00)
                                        }
                                          }
                                      
                                          const handleClickSubtractFourthLevelSecond = () => {
                                            if (countfourthlevelsecond > 1) {
                                              setCountfourthlevelsecond(countfourthlevelsecond - 1.00)
                                            }
                                            }
                                            
                                              const handleClickFourthLevelSecond = (event) => {
                                                let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                setCountfourthlevelsecond(valueNumb)                
                                              }
                                            
                                              const handleClickAddFourthLevelSecond = () => {
                                                let max = 999
                                            if (countfourthlevelsecond < max) {
                                            setCountfourthlevelsecond(countfourthlevelsecond + 2.50)
                                            }
                                              }
                                      
                                              const handleClickSubtractFourthLevelThird = () => {
                                                if (countfourthlevelthird > 1) {
                                                  setCountfourthlevelthird(countfourthlevelthird - 1.00)
                                                }
                                                }
                                                
                                                  const handleClickFourthLevelThird = (event) => {
                                                    let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                    setCountfourthlevelthird(valueNumb)                
                                                  }
                                                
                                                  const handleClickAddFourthLevelThird = () => {
                                                    let max = 999
                                                if (countfourthlevelthird < max) {
                                                setCountfourthlevelthird(countfourthlevelthird + 1.00)
                                                }
                                                  }

                                                  const handleClickSubtractFifthLevelFirst = () => {
                                                    if (countfifthlevelfirst > 1) {
                                                      setCountfifthlevelfirst(countfifthlevelfirst - 1.00)
                                                    }
                                                    }
                                                    
                                                      const handleClickFifthLevelFirst = (event) => {
                                                        let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                        setCountfifthlevelfirst(valueNumb)              
                                                      }
                                                    
                                                      const handleClickAddFifthLevelFirst = () => {
                                                        let max = 999
                                                    if (countfifthlevelfirst < max) {
                                                    setCountfifthlevelfirst(countfifthlevelfirst + 5.00)
                                                    }
                                                      }
                                                  
                                                      const handleClickSubtractFifthLevelSecond = () => {
                                                        if (countfifthlevelsecond > 1) {
                                                          setCountfifthlevelsecond(countfifthlevelsecond - 1.00)
                                                        }
                                                        }
                                                        
                                                          const handleClickFifthLevelSecond = (event) => {
                                                            let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                            setCountfifthlevelsecond(valueNumb)                
                                                          }
                                                        
                                                          const handleClickAddFifthLevelSecond = () => {
                                                            let max = 999
                                                        if (countfifthlevelsecond < max) {
                                                        setCountfifthlevelsecond(countfifthlevelsecond + 2.50)
                                                        }
                                                          }
                                                  
                                                          const handleClickSubtractFifthLevelThird = () => {
                                                            if (countfifthlevelthird > 1) {
                                                              setCountfifthlevelthird(countfifthlevelthird - 1.00)
                                                            }
                                                            }
                                                            
                                                              const handleClickFifthLevelThird = (event) => {
                                                                let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                                setCountfifthlevelthird(valueNumb)                
                                                              }
                                                            
                                                              const handleClickAddFifthLevelThird = () => {
                                                                let max = 999
                                                            if (countfifthlevelthird < max) {
                                                            setCountfifthlevelthird(countfifthlevelthird + 1.00)
                                                            }
                                                              }

                                                              const handleClickSubtractSixthLevelFirst = () => {
                                                                if (countsixthlevelfirst > 1) {
                                                                  setCountsixthlevelfirst(countsixthlevelfirst - 1.00)
                                                                }
                                                                }
                                                                
                                                                  const handleClickSixthLevelFirst = (event) => {
                                                                    let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                                    setCountsixthlevelfirst(valueNumb)              
                                                                  }
                                                                
                                                                  const handleClickAddSixthLevelFirst = () => {
                                                                    let max = 999
                                                                if (countsixthlevelfirst < max) {
                                                                setCountsixthlevelfirst(countsixthlevelfirst + 5.00)
                                                                }
                                                                  }
                                                              
                                                                  const handleClickSubtractSixthLevelSecond = () => {
                                                                    if (countsixthlevelsecond > 1) {
                                                                      setCountsixthlevelsecond(countsixthlevelsecond - 1.00)
                                                                    }
                                                                    }
                                                                    
                                                                      const handleClickSixthLevelSecond = (event) => {
                                                                        let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                                        setCountsixthlevelsecond(valueNumb)                
                                                                      }
                                                                    
                                                                      const handleClickAddSixthLevelSecond = () => {
                                                                        let max = 999
                                                                    if (countsixthlevelsecond < max) {
                                                                    setCountsixthlevelsecond(countsixthlevelsecond + 2.50)
                                                                    }
                                                                      }
                                                              
                                                                      const handleClickSubtractSixthLevelThird = () => {
                                                                        if (countsixthlevelthird > 1) {
                                                                          setCountsixthlevelthird(countsixthlevelthird - 1.00)
                                                                        }
                                                                        }
                                                                        
                                                                          const handleClickSixthLevelThird = (event) => {
                                                                            let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                                            setCountsixthlevelthird(valueNumb)                
                                                                          }
                                                                        
                                                                          const handleClickAddSixthLevelThird = () => {
                                                                            let max = 999
                                                                        if (countsixthlevelthird < max) {
                                                                        setCountsixthlevelthird(countsixthlevelthird + 1.00)
                                                                        }
                                                                          }

                                                                          const handleClickSubtractSeventhLevelFirst = () => {
                                                                            if (countseventhlevelfirst > 1) {
                                                                              setCountseventhlevelfirst(countseventhlevelfirst - 1.00)
                                                                            }
                                                                            }
                                                                            
                                                                              const handleClickSeventhLevelFirst = (event) => {
                                                                                let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                                                setCountseventhlevelfirst(valueNumb)              
                                                                              }
                                                                            
                                                                              const handleClickAddSeventhLevelFirst = () => {
                                                                                let max = 999
                                                                            if (countseventhlevelfirst < max) {
                                                                            setCountseventhlevelfirst(countseventhlevelfirst + 5.00)
                                                                            }
                                                                              }
                                                                          
                                                                              const handleClickSubtractSeventhLevelSecond = () => {
                                                                                if (countseventhlevelsecond > 1) {
                                                                                  setCountseventhlevelsecond(countseventhlevelsecond - 1.00)
                                                                                }
                                                                                }
                                                                                
                                                                                  const handleClickSeventhLevelSecond = (event) => {
                                                                                    let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                                                    setCountseventhlevelsecond(valueNumb)                
                                                                                  }
                                                                                
                                                                                  const handleClickAddSeventhLevelSecond = () => {
                                                                                    let max = 999
                                                                                if (countseventhlevelsecond < max) {
                                                                                setCountseventhlevelsecond(countseventhlevelsecond + 2.50)
                                                                                }
                                                                                  }
                                                                          
                                                                                  const handleClickSubtractSeventhLevelThird = () => {
                                                                                    if (countseventhlevelthird > 1) {
                                                                                      setCountseventhlevelthird(countseventhlevelthird - 1.00)
                                                                                    }
                                                                                    }
                                                                                    
                                                                                      const handleClickSeventhLevelThird = (event) => {
                                                                                        let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                                                        setCountseventhlevelthird(valueNumb)                
                                                                                      }
                                                                                    
                                                                                      const handleClickAddSeventhLevelThird = () => {
                                                                                        let max = 999
                                                                                    if (countseventhlevelthird < max) {
                                                                                    setCountseventhlevelthird(countseventhlevelthird + 1.00)
                                                                                    }
                                                                                      }

                                                                                      const handleClickSubtractEighthLevelFirst = () => {
                                                                                        if (counteighthlevelfirst > 1) {
                                                                                          setCounteighthlevelfirst(counteighthlevelfirst - 1.00)
                                                                                        }
                                                                                        }
                                                                                        
                                                                                          const handleClickEighthLevelFirst = (event) => {
                                                                                            let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                                                            setCounteighthlevelfirst(valueNumb)              
                                                                                          }
                                                                                        
                                                                                          const handleClickAddEighthLevelFirst = () => {
                                                                                            let max = 999
                                                                                        if (counteighthlevelfirst < max) {
                                                                                        setCounteighthlevelfirst(counteighthlevelfirst + 5.00)
                                                                                        }
                                                                                          }
                                                                                      
                                                                                          const handleClickSubtractEighthLevelSecond = () => {
                                                                                            if (counteighthlevelsecond > 1) {
                                                                                              setCounteighthlevelsecond(counteighthlevelsecond - 1.00)
                                                                                            }
                                                                                            }
                                                                                            
                                                                                              const handleClickEighthLevelSecond = (event) => {
                                                                                                let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                                                                setCounteighthlevelsecond(valueNumb)                
                                                                                              }
                                                                                            
                                                                                              const handleClickAddEighthLevelSecond = () => {
                                                                                                let max = 999
                                                                                            if (counteighthlevelsecond < max) {
                                                                                            setCounteighthlevelsecond(counteighthlevelsecond + 2.50)
                                                                                            }
                                                                                              }
                                                                                      
                                                                                              const handleClickSubtractEighthLevelThird = () => {
                                                                                                if (counteighthlevelthird > 1) {
                                                                                                  setCounteighthlevelthird(counteighthlevelthird - 1.00)
                                                                                                }
                                                                                                }
                                                                                                
                                                                                                  const handleClickEighthLevelThird = (event) => {
                                                                                                    let valueNumb = fixRounding(event.target.valueAsNumber, 2)
                                                                                                    setCounteighthlevelthird(valueNumb)                
                                                                                                  }
                                                                                                
                                                                                                  const handleClickAddEighthLevelThird = () => {
                                                                                                    let max = 999
                                                                                                if (counteighthlevelthird < max) {
                                                                                                setCounteighthlevelthird(counteighthlevelthird + 1.00)
                                                                                                }
                                                                                                  }

  const handleClickSubtractFirst = () => {
    if (countfirst > 1) {
      setCountfirst(countfirst - 1)
    }

}

  const handleClickAddFirst = () => {
    let max = 999
    if (countfirst < max) {
    setCountfirst(countfirst + 1)
    }
  }

  const handleClickFirst = (event) => {
setCountfirst(event.target.valueAsNumber)
  }

  const handleClickSubtractSecond = () => {
    if (countsecond > 1) {
      setCountsecond(countsecond - 1)
    }
    }
    
      const handleClickAddSecond = () => {    
    let max = 999
    if (countsecond < max) {
    setCountsecond(countsecond + 1)
    }
      }
    
      const handleClickSecond = (event) => {
    setCountsecond(event.target.valueAsNumber)
      }

      const handleClickSubtractThird = () => {
        if (countthird > 1) {
          setCountthird(countthird - 1)
        }
        }
        
         
        const handleClickAddThird = () => {
          let max = 999
          if (countthird < max) {
          setCountthird(countthird + 1)
          }
          }
        
          const handleClickThird = (event) => {
        setCountthird(event.target.valueAsNumber)
          }

          const handleStartChange = (event) => {
            setStartdate(event.target.value)
          }

          const handleEndChange = (event) => {
            setEnddate(event.target.value)
          }

          const handleEndNever = () => {
            if (!neverdate) {
              const todate = new Date()
              todate.setFullYear(new Date().getFullYear() + 10)              
              setEnddate(todate.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'}))
                          }
setNeverdate(!neverdate)
          }

          const handleStartNow = () => {
            if (!nowdate) {
              const todate = new Date().toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
              setStartdate(todate)
                          }
setNowdate(!nowdate)
          }

  const handleTagChange = (event) => {
    const tag_1 = event.target.value.replace("[ ","")
    const tag_2 = tag_1.replace(" ]","")
    if (tag_2.includes(']') || tag_2.includes('[') || tag_2.includes(' ')) {
setTag('')
      alert('Do not put ], [ or empty spaces in your tag!')
return
    }
    setTag(tag_2)
  }

  const handleBalance = () => {
    setLoading(true)
    let countV = countfirstlevelsecond
    if (countfirstlevelfirst >= 1) {
      countV = fixRounding(countfirstlevelfirst/2, 2)
setCountfirstlevelsecond(countV)
    }
    if (countV >= 1) {
      setCountfirstlevelthird(fixRounding(countV/2, 2))
    }
    countV = countsecondlevelsecond
    if (countsecondlevelfirst >= 1) {
      countV = fixRounding(countsecondlevelfirst/2, 2)
setCountsecondlevelsecond(countV)
    }
    if (countV >= 1) {
      setCountsecondlevelthird(fixRounding(countV/2, 2))
    }
    countV = countthirdlevelsecond
    if (countthirdlevelfirst >= 1) {
      countV = fixRounding(countthirdlevelfirst/2, 2)
setCountthirdlevelsecond(countV)
    }
    if (countV >= 1) {
      setCountthirdlevelthird(fixRounding(countV/2, 2))
    }
    countV = countfourthlevelsecond
    if (countfourthlevelfirst >= 1) {
      countV = fixRounding(countfourthlevelfirst/2, 2)
setCountfourthlevelsecond(countV)
    }
    if (countV >= 1) {
      setCountfourthlevelthird(fixRounding(countV/2, 2))
    }
    countV = countfifthlevelsecond
    if (countfifthlevelfirst >= 1) {
      countV = fixRounding(countfifthlevelfirst/2, 2)
setCountfifthlevelsecond(countV)
    }
    if (countV >= 1) {
      setCountfifthlevelthird(fixRounding(countV/2, 2))
    }
    countV = countsixthlevelsecond
    if (countsixthlevelfirst >= 1) {
      countV = fixRounding(countsixthlevelfirst/2, 2)
setCountsixthlevelsecond(countV)
    }
    if (countV >= 1) {
      setCountsixthlevelthird(fixRounding(countV/2, 2))
    }
    countV = countseventhlevelsecond
    if (countseventhlevelfirst >= 1) {
      countV = fixRounding(countseventhlevelfirst/2, 2)
setCountseventhlevelsecond(countV)
    }
    if (countV >= 1) {
      setCountseventhlevelthird(fixRounding(countV/2, 2))
    }
    countV = counteighthlevelsecond
    if (counteighthlevelfirst >= 1) {
      countV = fixRounding(counteighthlevelfirst/2, 2)
setCounteighthlevelsecond(countV)
    }
    if (countV >= 1) {
      setCounteighthlevelthird(fixRounding(countV/2, 2))
    }
    setLoading(false)
  }

  const renderPage = () => {
    return (
      <div>
  <Grid container  centered relaxed verticalAlign="middle" style={{marginTop: '-0.1em', maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh'}}>
                <Grid.Row columns={1} centered >
                  <Grid.Column style={{minWidth: '20em',maxWidth: '33em'}} verticalAlign="middle" textAlign="center">
      <Form style={{ margin: '0 auto' }}>
        <div style={divBadgeStyle}>
        <label style={{fontSize: '1.1rem', fontWeight: "bold", color: '#6435c9'}}>Reward Advanced Creation</label>
        </div>   
        <div style={divStyle}>
        <div style={{cursor: 'pointer' }} onClick={handleStartNow}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Starting Date</label>
        </div>
        {nowdate && <div style={{cursor: 'pointer' }} onClick={handleStartNow}>
          <Label size='huge' color='orange' style={{fontSize: '0.8rem', fontWeight: "bold"}}><strong>NOW</strong></Label>
          </div>
          }
        {!nowdate && <input
          className='inputform'
          style={inputStyle}
          placeholder='DD/MM/YYYY'
                    value={startdate}
                    onChange={handleStartChange}
                  />}
          </div>
          <div style={divStyle}>
          <div style={{cursor: 'pointer' }} onClick={handleEndNever}>
          <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Ending Date</label>
        </div>
        {neverdate && <div style={{cursor: 'pointer' }} onClick={handleEndNever}>
          <Label size='huge' color='orange' style={{fontSize: '0.8rem', fontWeight: "bold"}}><strong>NEVER</strong></Label>
          </div>
          }    
        {!neverdate && <input
          className='inputform'
          style={inputStyle}
          placeholder='DD/MM/YYYY'
                    value={enddate}
                    onChange={handleEndChange}
                  />}
          </div>
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold", color: '#6435c9'}}>Set a Tag</label>
          <input
          className='inputform'
          style={inputStyle}
                    value={"[ " + tag + " ]"}
                    onChange={handleTagChange}
                  /> 
          </div>  
        <div style={divStyle}>
        <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>First Prize - Max Sessions</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractFirst}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countfirst}
                  onChange={handleClickFirst}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddFirst}
            >
              +
              </Button>
                </div>
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Second Prize - Max Sessions</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractSecond}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countsecond}
                  onChange={handleClickSecond}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddSecond}
            >
              +
              </Button>
                </div>
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Third Prize - Max Sessions</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        {countthird !== rewardsConfig.max_session_third_prize && <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractThird}
            >
              -
              </Button>}
              {countthird !== rewardsConfig.max_session_third_prize && <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countthird}
                  onChange={handleClickThird}
                />}
              {countthird !== rewardsConfig.max_session_third_prize && <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddThird}
            >
              +
              </Button>}
              {countthird === rewardsConfig.max_session_third_prize && <div style={{cursor: 'pointer' }} onClick={handleUnlimited}>
                <Label size='huge' color='orange' style={{fontSize: '0.8rem', fontWeight: "bold"}}><strong>UNLIMITED</strong></Label>
                </div>}
                </div>
        </div>
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold"}}>First Level</label>
        <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
<label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countfirst} Sessions</label>
              </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractFirstLevelFirst}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countfirstlevelfirst}
                  onChange={handleClickFirstLevelFirst}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddFirstLevelFirst}
            >
              +
              </Button>
                </div> 
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countsecond} Sessions</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractFirstLevelSecond}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countfirstlevelsecond}
                  onChange={handleClickFirstLevelSecond}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddFirstLevelSecond}
            >
              +
              </Button>
                </div>  
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>{countthird !== rewardsConfig.max_session_third_prize && `Prize if Completed in ${countthird} Sessions`}{countthird === rewardsConfig.max_session_third_prize && `Prize if Completed in Any number of Sessions`}</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractFirstLevelThird}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countfirstlevelthird}
                  onChange={handleClickFirstLevelThird}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddFirstLevelThird}
            >
              +
              </Button>
                </div>  
        </div>
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold"}}>Second Level</label>
        <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
<label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countfirst} Sessions</label>
              </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractSecondLevelFirst}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countsecondlevelfirst}
                  onChange={handleClickSecondLevelFirst}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddSecondLevelFirst}
            >
              +
              </Button>
                </div> 
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countsecond} Sessions</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractSecondLevelSecond}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countsecondlevelsecond}
                  onChange={handleClickSecondLevelSecond}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddSecondLevelSecond}
            >
              +
              </Button>
                </div>  
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>{countthird !== rewardsConfig.max_session_third_prize && `Prize if Completed in ${countthird} Sessions`}{countthird === rewardsConfig.max_session_third_prize && `Prize if Completed in Any number of Sessions`}</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractSecondLevelThird}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countsecondlevelthird}
                  onChange={handleClickSecondLevelThird}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddSecondLevelThird}
            >
              +
              </Button>
                </div>  
        </div>
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold"}}>Third Level</label>
        <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
<label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countfirst} Sessions</label>
              </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractThirdLevelFirst}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countthirdlevelfirst}
                  onChange={handleClickThirdLevelFirst}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddThirdLevelFirst}
            >
              +
              </Button>
                </div> 
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countsecond} Sessions</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractThirdLevelSecond}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countthirdlevelsecond}
                  onChange={handleClickThirdLevelSecond}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddThirdLevelSecond}
            >
              +
              </Button>
                </div>  
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>{countthird !== rewardsConfig.max_session_third_prize && `Prize if Completed in ${countthird} Sessions`}{countthird === rewardsConfig.max_session_third_prize && `Prize if Completed in Any number of Sessions`}</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractThirdLevelThird}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countthirdlevelthird}
                  onChange={handleClickThirdLevelThird}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddThirdLevelThird}
            >
              +
              </Button>
                </div>  
        </div>
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold"}}>Fourth Level</label>
        <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
<label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countfirst} Sessions</label>
              </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractFourthLevelFirst}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countfourthlevelfirst}
                  onChange={handleClickFourthLevelFirst}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddFourthLevelFirst}
            >
              +
              </Button>
                </div> 
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countsecond} Sessions</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractFourthLevelSecond}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countfourthlevelsecond}
                  onChange={handleClickFourthLevelSecond}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddFourthLevelSecond}
            >
              +
              </Button>
                </div>  
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>{countthird !== rewardsConfig.max_session_third_prize && `Prize if Completed in ${countthird} Sessions`}{countthird === rewardsConfig.max_session_third_prize && `Prize if Completed in Any number of Sessions`}</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractFourthLevelThird}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countfourthlevelthird}
                  onChange={handleClickFourthLevelThird}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddFourthLevelThird}
            >
              +
              </Button>
                </div>  
        </div>
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold"}}>Fifth Level</label>
        <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
<label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countfirst} Sessions</label>
              </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractFifthLevelFirst}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countfifthlevelfirst}
                  onChange={handleClickFifthLevelFirst}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddFifthLevelFirst}
            >
              +
              </Button>
                </div> 
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countsecond} Sessions</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractFifthLevelSecond}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countfifthlevelsecond}
                  onChange={handleClickFifthLevelSecond}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddFifthLevelSecond}
            >
              +
              </Button>
                </div>  
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>{countthird !== rewardsConfig.max_session_third_prize && `Prize if Completed in ${countthird} Sessions`}{countthird === rewardsConfig.max_session_third_prize && `Prize if Completed in Any number of Sessions`}</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractFifthLevelThird}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countfifthlevelthird}
                  onChange={handleClickFifthLevelThird}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddFifthLevelThird}
            >
              +
              </Button>
                </div>  
        </div>
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold"}}>Sixth Level</label>
        <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
<label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countfirst} Sessions</label>
              </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractSixthLevelFirst}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countsixthlevelfirst}
                  onChange={handleClickSixthLevelFirst}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddSixthLevelFirst}
            >
              +
              </Button>
                </div> 
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countsecond} Sessions</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractSixthLevelSecond}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countsixthlevelsecond}
                  onChange={handleClickSixthLevelSecond}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddSixthLevelSecond}
            >
              +
              </Button>
                </div>  
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>{countthird !== rewardsConfig.max_session_third_prize && `Prize if Completed in ${countthird} Sessions`}{countthird === rewardsConfig.max_session_third_prize && `Prize if Completed in Any number of Sessions`}</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractSixthLevelThird}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countsixthlevelthird}
                  onChange={handleClickSixthLevelThird}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddSixthLevelThird}
            >
              +
              </Button>
                </div>  
        </div>
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold"}}>Seventh Level</label>
        <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
<label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countfirst} Sessions</label>
              </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractSeventhLevelFirst}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countseventhlevelfirst}
                  onChange={handleClickSeventhLevelFirst}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddSeventhLevelFirst}
            >
              +
              </Button>
                </div> 
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countsecond} Sessions</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractSeventhLevelSecond}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countseventhlevelsecond}
                  onChange={handleClickSeventhLevelSecond}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddSeventhLevelSecond}
            >
              +
              </Button>
                </div>  
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>{countthird !== rewardsConfig.max_session_third_prize && `Prize if Completed in ${countthird} Sessions`}{countthird === rewardsConfig.max_session_third_prize && `Prize if Completed in Any number of Sessions`}</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractSeventhLevelThird}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={countseventhlevelthird}
                  onChange={handleClickSeventhLevelThird}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddSeventhLevelThird}
            >
              +
              </Button>
                </div>  
        </div>
        <div style={divStyle}>
        <label style={{fontSize: '0.9rem', fontWeight: "bold"}}>Eighth Level</label>
        <div style={{
  fontSize: '0.8rem',
  textAlign: 'center',
  border: '0em'
}}>
<label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countfirst} Sessions</label>
              </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractEighthLevelFirst}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={counteighthlevelfirst}
                  onChange={handleClickEighthLevelFirst}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddEighthLevelFirst}
            >
              +
              </Button>
                </div> 
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>Prize if Completed in {countsecond} Sessions</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractEighthLevelSecond}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={counteighthlevelsecond}
                  onChange={handleClickEighthLevelSecond}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddEighthLevelSecond}
            >
              +
              </Button>
                </div>  
                <label style={{fontSize: '0.8rem', fontWeight: "bold", color: '#6435c9'}}>{countthird !== rewardsConfig.max_session_third_prize && `Prize if Completed in ${countthird} Sessions`}{countthird === rewardsConfig.max_session_third_prize && `Prize if Completed in Any number of Sessions`}</label>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickSubtractEighthLevelThird}
            >
              -
              </Button>
              <input
                className='inputform'
                style={inputStyle}
                disabled
                  value={counteighthlevelthird}
                  onChange={handleClickEighthLevelThird}
                />
              <Button
        size="small"
              color="orange"
              loading={loading}
              onClick={handleClickAddEighthLevelThird}
            >
              +
              </Button>
                </div>  
        </div>
        <Popup content='Balance your Prizes with the Standard Ratio' trigger={<Button
                   size="small"
                         color="violet"
                         loading={loading}
                         onClick={handleBalance}
                       >
                         Auto-Balance
                         </Button>} />
        <Button
              fluid
              color="orange"
              onClick={handleTargets}
              loading={loading}
              style={{marginTop: '0.3em'}}
            >
              Choose your Targets
              </Button>
      </Form>
      </Grid.Column>
                </Grid.Row>            
              </Grid>
              <Grid container celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", overflow: 'auto', maxWidth: '90vh', marginTop: '5em'}}>
              <Grid.Row columns={1}>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', fontSize: '0.9rem'}}>Group of Rewards - Recap</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={14} style={{minWidth: "150em"}}>
            <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>Tag</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>Start Date</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>End Date</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>First Prize Max Sessions</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>Second Prize Max Sessions</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>Third Prize Max Sessions</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>First Level Rewards</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>Second Level Rewards</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>Third Level Rewards</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>Fourth Level Rewards</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>Fifth Level Rewards</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>Sixth Level Rewards</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>Seventh Level Rewards</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", overflow: 'auto', padding: '0.2em'}}>Eighth Level Rewards</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={14} style={{minWidth: "150em"}}>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{tag}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{startdate && startdate}{!startdate && 'Now'}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{enddate && enddate}{!enddate && 'Never'}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{countfirst}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{countsecond}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{countthird === rewardsConfig.max_session_third_prize && 'Unlimited'}{countthird !== rewardsConfig.max_session_third_prize && countthird}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{`${countfirstlevelfirst},${countfirstlevelsecond},${countfirstlevelthird} EUR`}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{`${countsecondlevelfirst},${countsecondlevelsecond},${countsecondlevelthird} EUR`}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{`${countthirdlevelfirst},${countthirdlevelsecond},${countthirdlevelthird} EUR`}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{`${countfourthlevelfirst},${countfourthlevelsecond},${countfourthlevelthird} EUR`}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{`${countfifthlevelfirst},${countfifthlevelsecond},${countfifthlevelthird} EUR`}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{`${countsixthlevelfirst},${countsixthlevelsecond},${countsixthlevelthird} EUR`}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{`${countseventhlevelfirst},${countseventhlevelsecond},${countseventhlevelthird} EUR`}</Grid.Column>
              <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>{`${counteighthlevelfirst},${counteighthlevelsecond},${counteighthlevelthird} EUR`}</Grid.Column>
              </Grid.Row>
              </Grid>
              <Grid container columns="equal" centered relaxed verticalAlign="middle" style={{ margin: '0.5em'}}>
              <Grid.Row columns={1} centered style={{margin: '0.5em'}}>
              <Grid.Column textAlign="center">
                    <Button
                            loading={loading}
                            color="violet"
                            fluid
                            className="action-button"      
                            onClick={handleReturn}                    
                          >
                            Cancel
                          </Button>
                      </Grid.Column>
                  </Grid.Row>
                  </Grid>
              {open && <RewardsTargetList handleyes={handleYes} handleno={handleNo} checkopen={open} idarray={props.match.params.idarray}/>}
      </div>
    );
  }

  return renderPage()
};

