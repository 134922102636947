import * as React from 'react'
import { Checkbox,Grid } from 'semantic-ui-react'
import { RewardTarget } from '../types/RewardModels'

interface CheckElementProps {
    userId: string,
    onUnCheck: any,
    onCheck: any,
    onSelectCheck: boolean,
    onSelectClear: boolean,
    reward: RewardTarget
  }
  
  interface CheckElementState {
      checked: boolean,
      checkselect: boolean
  }
  
  export class CheckElementRewardTarget extends React.PureComponent<CheckElementProps, CheckElementState> {
    state: CheckElementState = {
        checked: false,
        checkselect: false
      }
    toggle = () => {this.setState((prevState: any) => ({ checked: !prevState.checked }))}
    handleCheck = () => {this.props.onCheck(this.props.userId)}
    handleUnCheck = () => {this.props.onUnCheck(this.props.userId)}
    shouldComponentUpdate(nextProps: CheckElementProps, nextState: CheckElementState) {
      if (this.props.onSelectCheck !== nextProps.onSelectCheck) {
          this.setState({ checked: true, checkselect: !this.state.checkselect })
          return false
      }
    if (this.props.onSelectClear !== nextProps.onSelectClear) {
        this.setState({ checked: false, checkselect: !this.state.checkselect })
        return false
    }    
    if (this.state.checkselect === nextState.checkselect) {
      if (this.state.checked !== nextState.checked) {
        if (nextState.checked) {
    this.handleCheck()
    } else {
      this.handleUnCheck()
      }
    }
  }
return true
}
    render() {
      return (
        <Grid.Row style={{minWidth: '72em'}} column={4}>
              <Grid.Column style={{padding: "0.2em"}}>{this.props.reward.type}</Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.name}</Grid.Column>
              <Grid.Column style={{padding: "0.2em"}}>{this.props.reward.email}</Grid.Column>
              <Grid.Column style={{padding: "0.2em"}}>
                <Checkbox
                checked={this.state.checked}
          onClick={this.toggle}
        />
        </Grid.Column>
        </Grid.Row>
      )
    }
  }