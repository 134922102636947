/* eslint-disable array-callback-return */
import * as React from 'react'
import { Grid, Button, Loader, Message } from 'semantic-ui-react'
import { getRewards, deleteReward, getUsers, getInfoUser } from '../api/users-api'
import  { Auth } from '../auth/Auth'
import { HeaderCustom } from './HeaderCustom'
import { RewardDistributionTarget, RewardFormatted, RewardTarget, SingleDistributionFormat, SingleRewardModel } from '../types/RewardModels'
import { CSVLink } from 'react-csv'
import { CheckElementReward } from './CheckElementReward'
import { UserModel } from '../types/UserModel'

const { rewardsConfig } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

interface CreateRewardsProps {
  auth: Auth,
  history: any
}

interface CreateRewardsState {
  idCheck: string[],
  idView: string[],
  formInput: string,
  selectCheck: boolean,
  selectClear: boolean,
  loading: boolean,
  rewards: RewardFormatted[],
  loadingbutton: boolean,
  update: boolean,
  reqExpiration: string,
  csvOptions: any,
  sendDelete: string,
  statusDel: string,
  rewardTargets: RewardTarget[],
  exportEnabled: boolean,
  csvSingleOptions: any,
  csvDistOptions: any
}

function fixRounding(value: number, precision: number) {
  let power = Math.pow(10, precision || 0)
  return Math.round(value * power) / power
}

export class CreateRewards extends React.PureComponent<
  CreateRewardsProps,
  CreateRewardsState
> {
  state: CreateRewardsState = {
    idCheck: [],
    idView: [],
    formInput: '',
    selectCheck: false,
    selectClear: true,
    csvOptions: {},
    loading: true,
    rewards: [],
    loadingbutton: false,
    update: false,
    reqExpiration: '',
    sendDelete: 'none',
    statusDel: 'delete process not started yet.',
    rewardTargets: [],
    exportEnabled: false,
    csvSingleOptions: {},
    csvDistOptions: {}
  }

  csvLink = React.createRef<HTMLDivElement>()

  csvSingleLink = React.createRef<HTMLDivElement>()

  csvDistLink = React.createRef<HTMLDivElement>()

  handleDownload = () => {
    this.csvLink.current ? this.csvLink.current.click() : alert('Unkwnown error!')
      }

      handleSingleDownload = () => {
        this.csvSingleLink.current ? this.csvSingleLink.current.click() : alert('Unkwnown error!')
          }

          handleDistDownload = () => {
            this.csvDistLink.current ? this.csvDistLink.current.click() : alert('Unkwnown error!')
              }

  handleSelectAll = () => {
    let idAll = this.state.idView
    if (this.state.selectCheck) {
      this.setState({ idCheck: idAll, selectCheck: false })
    } else {
    this.setState({ idCheck: idAll, selectCheck: true })
    }
  }

  handleClear = () => {
    if (this.state.selectClear) {
    this.setState({ idCheck: [], selectClear: false })
    } else {
      this.setState({ idCheck: [], selectClear: true }) 
    }
  }

  handleCreateAllUserId = (userIdarray: string[]) => {
    let idCheckNew = userIdarray
      this.setState({ idView: idCheckNew })
  }

  handleDeleteAllUserId = () => {
    this.setState({ idView: [] })
  }

  handleIdCheckPush = (userId: string) => {
    let idCheckNew = this.state.idCheck.concat([userId])
      this.setState({ idCheck: idCheckNew })
      console.log('checkpush: ' + userId + 'idcheck: ' + this.state.idCheck)
  }

  handleIdCheckDelete = (userId: string) => {
    let idCheckNew = this.state.idCheck.filter(
      id => id !== userId
    )
      this.setState({ idCheck: idCheckNew })
      console.log('checkdelete: ' + userId + 'idcheck: ' + this.state.idCheck)
  }

  shouldComponentUpdate(nextProps: CreateRewardsProps, nextState: CreateRewardsState) {
    if (nextState.idView !== this.state.idView) {return false}
    return true
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.update === this.state.update) {
      let rewardArrFormatted: RewardFormatted[] = []
      let rewardArrTargets: RewardTarget[] = []
      try {
        const users = await getUsers(this.props.auth.getIdToken())
        for (let index = 0; index < users.length; index++) {
          const element = users[index];
          let elementObj: any = null
          if (element.type === 'company') {
elementObj = {
name: element.company_name,
type: element.type,
email: element.email,
id: element.id
}
          }
          if (element.type === 'user') {
const userPaymentInfo: UserModel = await getInfoUser(this.props.auth.getIdToken(), element.id)
      const usergroup = userPaymentInfo.usergroup
      if (usergroup === 'guests' || usergroup === 'members') {
        elementObj = {
          name: element.name,
          type: element.type,
          email: element.email,
          id: element.id
          }
      }
          }
          if (elementObj) {
            rewardArrTargets.push(elementObj)
          }              
        }
        const rewards = await getRewards(this.props.auth.getIdToken())
        for (let index = 0; index < rewards.length; index++) {
          let rewardFormatted: RewardFormatted = {
            start_date: '',
            end_date: '',
            targets: null,
            reward_first_level: null,
  reward_second_level: null,
  reward_third_level: null,
  reward_fourth_level: null,
  reward_fifth_level: null,
  reward_sixth_level: null,
  reward_seventh_level: null,
  reward_eighth_level: null,
            sessions_first: null,
            sessions_second: null,
            sessions_third: null,
            creation_date: null,
            creation_stamp: null,
            tag: null,
            id: null,
            rewarded: '0',
            distribution: null
          }
          let rewardsFirst = rewards[index].rewards_first.S
          let rewardsSecond = rewards[index].rewards_second.S
          let rewardsThird = rewards[index].rewards_third.S
          let rewardsFirstSplitted = rewardsFirst.split(',')
          let rewardsSecondSplitted = rewardsSecond.split(',')
          let rewardsThirdSplitted = rewardsThird.split(',')
          let toDate = new Date().getTime()
          let expFormatted = new Date(parseInt(rewards[index].end_date.S))
          let createFormatted = new Date(parseInt(rewards[index].creation_date.S))
          let startFormatted = new Date(parseInt(rewards[index].start_date.S))
          rewardFormatted.start_date = startFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
          rewardFormatted.creation_date = createFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
          rewardFormatted.end_date = expFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
          rewardFormatted.creation_stamp = rewards[index].creation_date.S
          rewardFormatted.targets = rewards[index].targets.S
          rewardFormatted.id = rewards[index].id.S
          rewardFormatted.tag = rewards[index].tag.S
          rewardFormatted.rewarded = rewards[index].rewarded.S
          rewardFormatted.reward_first_level = rewardsFirstSplitted[0] + ',' + rewardsSecondSplitted[0] + ',' + rewardsThirdSplitted[0]
          rewardFormatted.reward_second_level = rewardsFirstSplitted[1] + ',' + rewardsSecondSplitted[1] + ',' + rewardsThirdSplitted[1]
          rewardFormatted.reward_third_level = rewardsFirstSplitted[2] + ',' + rewardsSecondSplitted[2] + ',' + rewardsThirdSplitted[2]
          rewardFormatted.reward_fourth_level = rewardsFirstSplitted[3] + ',' + rewardsSecondSplitted[3] + ',' + rewardsThirdSplitted[3]
          rewardFormatted.reward_fifth_level = rewardsFirstSplitted[4] + ',' + rewardsSecondSplitted[4] + ',' + rewardsThirdSplitted[4]
          rewardFormatted.reward_sixth_level = rewardsFirstSplitted[5] + ',' + rewardsSecondSplitted[5] + ',' + rewardsThirdSplitted[5]
          rewardFormatted.reward_seventh_level = rewardsFirstSplitted[6] + ',' + rewardsSecondSplitted[6] + ',' + rewardsThirdSplitted[6]
          rewardFormatted.reward_eighth_level = rewardsFirstSplitted[7] + ',' + rewardsSecondSplitted[7] + ',' + rewardsThirdSplitted[7]
          rewardFormatted.sessions_first = rewards[index].sessions_first.S
          rewardFormatted.sessions_second = rewards[index].sessions_second.S
          rewardFormatted.sessions_third = rewards[index].sessions_third.S
          rewardFormatted.distribution = rewards[index].distribution.S
if (parseInt(rewards[index].start_date.S) <= toDate) {
  rewardFormatted.status = 'Started and delivering'
  if (parseInt(rewards[index].end_date.S) <= toDate) {
    rewardFormatted.status = 'Rewards group delivered'
  }
} else {
  rewardFormatted.status = 'Not started yet'
}
          rewardArrFormatted.push(rewardFormatted)
        }
        if (rewardArrFormatted && rewardArrFormatted.length > 1) {
          rewardArrFormatted.sort((a, b) => {
            return parseInt(b.creation_stamp) - parseInt(a.creation_stamp)
          })
        }
        const headersCsv = [
          {label: 'ORDER', key: 'id'},
          {label: 'TIMESTAMP', key: 'creation_stamp'},
          {label: 'FIRST PRIZE MAX SESSIONS', key: 'sessions_first'},
          {label: 'SECOND PRIZE MAX SESSIONS', key: 'sessions_second'},
          {label: 'THIRD PRIZE MAX SESSIONS', key: 'sessions_third'},
          {label: 'FIRST LEVEL REWARDS (EUR)', key: 'rewards_first_level'},
          {label: 'SECOND LEVEL REWARDS (EUR)', key: 'rewards_second_level'},
          {label: 'THIRD LEVEL REWARDS (EUR)', key: 'rewards_third_level'},
          {label: 'FOURTH LEVEL REWARDS (EUR)', key: 'rewards_fourth_level'},
          {label: 'FIFTH LEVEL REWARDS (EUR)', key: 'rewards_fifth_level'},
          {label: 'SIXTH LEVEL REWARDS (EUR)', key: 'rewards_sixth_level'},
          {label: 'SEVENTH LEVEL REWARDS (EUR)', key: 'rewards_seventh_level'},
          {label: 'EIGHTH LEVEL REWARDS (EUR)', key: 'rewards_eighth_level'},
          {label: 'TARGETS', key: 'targets'},
          {label: 'TAG', key: 'tag'},
          {label: 'START DATE', key: 'start_date'},
          {label: 'END DATE', key: 'end_date'},
          {label: 'CREATION DATE', key: 'creation_date'},
          {label: 'STATUS', key: 'status'},
          {label: 'AMOUNT REWARDED (EUR)', key: 'rewarded'}
        ]
  this.setState((prevState: any) => ({ rewards: rewardArrFormatted, update: !prevState.update, csvOptions: {
    data: rewardArrFormatted,
  headers: headersCsv,
  filename: 'vvip-rewards.csv'
  }, rewardTargets: rewardArrTargets }))
    } catch (e) {
      console.log(e)
    }
    }
  }

  async componentDidMount() {
    this.setState({loading: true})
    let rewardArrFormatted: RewardFormatted[] = []
      let rewardArrTargets: RewardTarget[] = []
      try {
        const users = await getUsers(this.props.auth.getIdToken())
        for (let index = 0; index < users.length; index++) {
          const element = users[index];
          let elementObj: any = null
          if (element.type === 'company') {
elementObj = {
name: element.company_name,
type: element.type,
email: element.email,
id: element.id
}
          }
          if (element.type === 'user') {
const userPaymentInfo: UserModel = await getInfoUser(this.props.auth.getIdToken(), element.id)
      const usergroup = userPaymentInfo.usergroup
      if (usergroup === 'guests' || usergroup === 'members') {
        elementObj = {
          name: element.name,
          type: element.type,
          email: element.email,
          id: element.id
          }
      }
          }
          if (elementObj) {
            rewardArrTargets.push(elementObj)
          }              
        }
        const rewards = await getRewards(this.props.auth.getIdToken())
        for (let index = 0; index < rewards.length; index++) {
          let rewardFormatted: RewardFormatted = {
            start_date: '',
            end_date: '',
            targets: null,
            reward_first_level: null,
  reward_second_level: null,
  reward_third_level: null,
  reward_fourth_level: null,
  reward_fifth_level: null,
  reward_sixth_level: null,
  reward_seventh_level: null,
  reward_eighth_level: null,
            sessions_first: null,
            sessions_second: null,
            sessions_third: null,
            creation_date: null,
            creation_stamp: null,
            tag: null,
            id: null,
            rewarded: '0',
            distribution: null
          }
          let rewardsFirst = rewards[index].rewards_first.S
          let rewardsSecond = rewards[index].rewards_second.S
          let rewardsThird = rewards[index].rewards_third.S
          let rewardsFirstSplitted = rewardsFirst.split(',')
          let rewardsSecondSplitted = rewardsSecond.split(',')
          let rewardsThirdSplitted = rewardsThird.split(',')
          let toDate = new Date().getTime()
          let expFormatted = new Date(parseInt(rewards[index].end_date.S))
          let createFormatted = new Date(parseInt(rewards[index].creation_date.S))
          let startFormatted = new Date(parseInt(rewards[index].start_date.S))
          rewardFormatted.start_date = startFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
          rewardFormatted.creation_date = createFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
          rewardFormatted.end_date = expFormatted.toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
          rewardFormatted.creation_stamp = rewards[index].creation_date.S
          rewardFormatted.targets = rewards[index].targets.S
          rewardFormatted.id = rewards[index].id.S
          rewardFormatted.tag = rewards[index].tag.S
          rewardFormatted.rewarded = rewards[index].rewarded.S
          rewardFormatted.reward_first_level = rewardsFirstSplitted[0] + ',' + rewardsSecondSplitted[0] + ',' + rewardsThirdSplitted[0]
          rewardFormatted.reward_second_level = rewardsFirstSplitted[1] + ',' + rewardsSecondSplitted[1] + ',' + rewardsThirdSplitted[1]
          rewardFormatted.reward_third_level = rewardsFirstSplitted[2] + ',' + rewardsSecondSplitted[2] + ',' + rewardsThirdSplitted[2]
          rewardFormatted.reward_fourth_level = rewardsFirstSplitted[3] + ',' + rewardsSecondSplitted[3] + ',' + rewardsThirdSplitted[3]
          rewardFormatted.reward_fifth_level = rewardsFirstSplitted[4] + ',' + rewardsSecondSplitted[4] + ',' + rewardsThirdSplitted[4]
          rewardFormatted.reward_sixth_level = rewardsFirstSplitted[5] + ',' + rewardsSecondSplitted[5] + ',' + rewardsThirdSplitted[5]
          rewardFormatted.reward_seventh_level = rewardsFirstSplitted[6] + ',' + rewardsSecondSplitted[6] + ',' + rewardsThirdSplitted[6]
          rewardFormatted.reward_eighth_level = rewardsFirstSplitted[7] + ',' + rewardsSecondSplitted[7] + ',' + rewardsThirdSplitted[7]
          rewardFormatted.sessions_first = rewards[index].sessions_first.S
          rewardFormatted.sessions_second = rewards[index].sessions_second.S
          rewardFormatted.sessions_third = rewards[index].sessions_third.S
          rewardFormatted.distribution = rewards[index].distribution.S
          if (parseInt(rewards[index].start_date.S) <= toDate) {
            rewardFormatted.status = 'Started and delivering'
            if (parseInt(rewards[index].end_date.S) <= toDate) {
              rewardFormatted.status = 'Rewards group delivered'
            }
          } else {
            rewardFormatted.status = 'Not started yet'
          }
          rewardArrFormatted.push(rewardFormatted)
        }
        if (rewardArrFormatted && rewardArrFormatted.length > 1) {
          rewardArrFormatted.sort((a, b) => {
            return parseInt(b.creation_stamp) - parseInt(a.creation_stamp)
          })
        }
        const headersCsv = [
          {label: 'ORDER', key: 'id'},
          {label: 'TIMESTAMP', key: 'creation_stamp'},
          {label: 'FIRST PRIZE MAX SESSIONS', key: 'sessions_first'},
          {label: 'SECOND PRIZE MAX SESSIONS', key: 'sessions_second'},
          {label: 'THIRD PRIZE MAX SESSIONS', key: 'sessions_third'},
          {label: 'FIRST LEVEL REWARDS (EUR)', key: 'rewards_first_level'},
          {label: 'SECOND LEVEL REWARDS (EUR)', key: 'rewards_second_level'},
          {label: 'THIRD LEVEL REWARDS (EUR)', key: 'rewards_third_level'},
          {label: 'FOURTH LEVEL REWARDS (EUR)', key: 'rewards_fourth_level'},
          {label: 'FIFTH LEVEL REWARDS (EUR)', key: 'rewards_fifth_level'},
          {label: 'SIXTH LEVEL REWARDS (EUR)', key: 'rewards_sixth_level'},
          {label: 'SEVENTH LEVEL REWARDS (EUR)', key: 'rewards_seventh_level'},
          {label: 'EIGHTH LEVEL REWARDS (EUR)', key: 'rewards_eighth_level'},
          {label: 'TARGETS', key: 'targets'},
          {label: 'TAG', key: 'tag'},
          {label: 'START DATE', key: 'start_date'},
          {label: 'END DATE', key: 'end_date'},
          {label: 'CREATION DATE', key: 'creation_date'},
          {label: 'STATUS', key: 'status'},
          {label: 'AMOUNT REWARDED (EUR)', key: 'rewarded'}
        ]

this.setState({ rewards: rewardArrFormatted, loading: false, csvOptions: {
  data: rewardArrFormatted,
headers: headersCsv,
filename: 'vvip-rewards.csv'
}, csvSingleOptions: {
  data: rewardArrFormatted,
headers: headersCsv,
filename: 'vvip-rewards.csv'
}, csvDistOptions: {
  data: rewardArrFormatted,
headers: headersCsv,
filename: 'vvip-rewards.csv'
}, rewardTargets: rewardArrTargets })
  } catch (e) {
    this.setState({loading: false})
    console.log(e)
  }
  }

  handleClickDelete = () => {
    this.setState({sendDelete: 'delete'})
    }

handleNo = () => {
  this.setState({exportEnabled: false, sendDelete: 'none', statusDel: 'delete process not started yet.', idCheck: [],
  idView: [],
  formInput: '',
  selectCheck: false,
  selectClear: true})
}

handleYes = async () => {
  try {
    this.setState({statusDel: 'deleting rewards...', loadingbutton: true})
    await deleteReward(
      this.props.auth.getIdToken(),
      this.state.idCheck.toString()
    )    
    this.setState({statusDel: 'rewards deleted successfully!', loadingbutton: false})
    alert('Reward was deleted!')
  } catch (e) {    
    this.setState({statusDel: 'delete process not started yet.', loadingbutton: false})
    alert('Could not delete the reward!')
  }
}

handleSingleCsv = async () => {
  let arrObj: SingleRewardModel = {
    start_date: '',
    end_date: '',
    reward_first_level: '',
    reward_second_level: '',
    reward_third_level: '',
    reward_fourth_level: '',
    reward_fifth_level: '',
    reward_sixth_level: '',
    reward_seventh_level: '',
    reward_eighth_level: '',
    sessions_first: '',
    sessions_second: '',
    sessions_third: '',
    creation_date: '',
    tag: '',
    rewarded: '',
    status: '',
    type: '',
    email: 'none'
  }
  let arrSingle: SingleRewardModel[] = []
  let arrTargets: SingleRewardModel[] = []
  let arrDist: RewardDistributionTarget[] = []
  let arrDistFormat: SingleDistributionFormat[] = []
  let max_sessions_first = ''
  let max_sessions_second = ''
  let max_sessions_third = ''
  let arrRewards_first: string[] = []
  let arrRewards_second: string[] = []
  let arrRewards_third: string[] = []
  try {
    this.setState({loadingbutton: true})
    for (let index = 0; index < this.state.rewards.length; index++) {
      const element = this.state.rewards[index];
      if (element.id === this.state.idCheck[0]) {
        let rewards_first_split = element.reward_first_level.split(',')
        let rewards_second_split = element.reward_second_level.split(',')
        let rewards_third_split = element.reward_third_level.split(',')
        let rewards_fourth_split = element.reward_fourth_level.split(',')
        let rewards_fifth_split = element.reward_fifth_level.split(',')
        let rewards_sixth_split = element.reward_sixth_level.split(',')
        let rewards_seventh_split = element.reward_seventh_level.split(',')
        let rewards_eighth_split = element.reward_eighth_level.split(',')      
        max_sessions_first = element.sessions_first
        max_sessions_second = element.sessions_second
        max_sessions_third = element.sessions_third
        arrRewards_first = [rewards_first_split[0],rewards_second_split[0],rewards_third_split[0],rewards_fourth_split[0],
        rewards_fifth_split[0],rewards_sixth_split[0],rewards_seventh_split[0],rewards_eighth_split[0]]
        arrRewards_second = [rewards_first_split[1],rewards_second_split[1],rewards_third_split[1],rewards_fourth_split[1],
        rewards_fifth_split[1],rewards_sixth_split[1],rewards_seventh_split[1],rewards_eighth_split[1]]
        arrRewards_third = [rewards_first_split[2],rewards_second_split[2],rewards_third_split[2],rewards_fourth_split[2],
        rewards_fifth_split[2],rewards_sixth_split[2],rewards_seventh_split[2],rewards_eighth_split[2]]
        arrDist = JSON.parse(element.distribution)
        arrObj.start_date = element.start_date
        arrObj.end_date = element.end_date
        arrObj.reward_first_level = element.reward_first_level
        arrObj.reward_second_level = element.reward_second_level
        arrObj.reward_third_level = element.reward_third_level
        arrObj.reward_fourth_level = element.reward_fourth_level
        arrObj.reward_fifth_level = element.reward_fifth_level
        arrObj.reward_sixth_level = element.reward_sixth_level
        arrObj.reward_seventh_level = element.reward_seventh_level
        arrObj.reward_eighth_level = element.reward_eighth_level
        arrObj.sessions_first = element.sessions_first
        arrObj.sessions_second = element.sessions_second
        element.sessions_third === rewardsConfig.max_session_third_prize.toString() ? arrObj.sessions_third = 'Unlimited' : arrObj.sessions_third = element.sessions_third
        arrObj.creation_date = element.creation_date
        arrObj.rewarded = element.rewarded
        arrObj.status = element.status
        arrObj.tag = element.tag
        arrObj.type = 'reward'
        arrSingle.push(arrObj)
        console.log(arrDistFormat,max_sessions_first,max_sessions_second,max_sessions_third,arrRewards_first,arrRewards_second,arrRewards_third)
        const targetSplit = element.targets.split(',')
        if (targetSplit[0] === 'all') {
          const users = await getUsers(this.props.auth.getIdToken())
for (let k = 0; k < users.length; k++) {
  const elementK = users[k];
  if (!element.targets.includes(elementK.company_name) && !element.targets.includes(elementK.email)) {
  arrObj.reward_first_level = 'not completed'
        arrObj.reward_second_level = 'not completed'
        arrObj.reward_third_level = 'not completed'
        arrObj.reward_fourth_level = 'not completed'
        arrObj.reward_fifth_level = 'not completed'
        arrObj.reward_sixth_level = 'not completed'
        arrObj.reward_seventh_level = 'not completed'
        arrObj.reward_eighth_level = 'not completed'
  arrObj.rewarded = '0'
  arrObj.type = elementK.type
  arrObj.email = elementK.email
if (elementK.company_name !== 'unconfirmed') {
arrObj.tag = elementK.company_name
} else {
  arrObj.tag = elementK.name
}
if (elementK.tokens_gained && elementK.tokens_gained !== 'unconfirmed') {
let tokenSplit = elementK.tokens_gained.split(',')
if (tokenSplit.length > 1) {
let objRewarded = 0
let statusReward = 'completed but not rewarded'
let testArr = []
switch (tokenSplit[3]) {
  case '2':
    testArr = arrDist[0].users
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
          }
        }
      }
    }
    arrObj.reward_first_level = statusReward
    arrObj.rewarded = objRewarded.toString()
    break;
  case '3':
    testArr = arrDist[0].users
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
          }
        }
      }
    }
    arrObj.reward_first_level = statusReward
    testArr = arrDist[1].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
          }
        }
      }
    }
    arrObj.reward_second_level = statusReward
    arrObj.rewarded = objRewarded.toString()
    break;
  case '4':
    testArr = arrDist[0].users
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
          }
        }
      }
    }
    arrObj.reward_first_level = statusReward
    testArr = arrDist[1].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
          }
        }
      }
    }
    arrObj.reward_second_level = statusReward
    testArr = arrDist[2].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
          }
        }
      }
    }
    arrObj.reward_third_level = statusReward
    arrObj.rewarded = objRewarded.toString()
    break;
  case '5':
    testArr = arrDist[0].users
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
          }
        }
      }
    }
    arrObj.reward_first_level = statusReward
    testArr = arrDist[1].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
          }
        }
      }
    }
    arrObj.reward_second_level = statusReward
    testArr = arrDist[2].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
          }
        }
      }
    }
    arrObj.reward_third_level = statusReward
    testArr = arrDist[3].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[3]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[3]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[3]), 2)
          }
        }
      }
    }
    arrObj.reward_fourth_level = statusReward
    arrObj.rewarded = objRewarded.toString()
    break;
  case '6':
    testArr = arrDist[0].users
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
          }
        }
      }
    }
    arrObj.reward_first_level = statusReward
    testArr = arrDist[1].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
          }
        }
      }
    }
    arrObj.reward_second_level = statusReward
    testArr = arrDist[2].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
          }
        }
      }
    }
    arrObj.reward_third_level = statusReward
    testArr = arrDist[3].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[3]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[3]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[3]), 2)
          }
        }
      }
    }
    arrObj.reward_fourth_level = statusReward
    testArr = arrDist[4].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[4]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[4]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[4]), 2)
          }
        }
      }
    }
    arrObj.reward_fifth_level = statusReward
    arrObj.rewarded = objRewarded.toString()
    break;
  case '7':
    testArr = arrDist[0].users
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
          }
        }
      }
    }
    arrObj.reward_first_level = statusReward
    testArr = arrDist[1].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
          }
        }
      }
    }
    arrObj.reward_second_level = statusReward
    testArr = arrDist[2].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
          }
        }
      }
    }
    arrObj.reward_third_level = statusReward
    testArr = arrDist[3].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[3]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[3]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[3]), 2)
          }
        }
      }
    }
    arrObj.reward_fourth_level = statusReward
    testArr = arrDist[4].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[4]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[4]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[4]), 2)
          }
        }
      }
    }
    arrObj.reward_fifth_level = statusReward
    testArr = arrDist[5].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[5]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[5]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[5]), 2)
          }
        }
      }
    }
    arrObj.reward_sixth_level = statusReward
    arrObj.rewarded = objRewarded.toString()
    break;
  case '8':
    testArr = arrDist[0].users
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
          }
        }
      }
    }
    arrObj.reward_first_level = statusReward
    testArr = arrDist[1].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
          }
        }
      }
    }
    arrObj.reward_second_level = statusReward
    testArr = arrDist[2].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
          }
        }
      }
    }
    arrObj.reward_third_level = statusReward
    testArr = arrDist[3].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[3]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[3]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[3]), 2)
          }
        }
      }
    }
    arrObj.reward_fourth_level = statusReward
    testArr = arrDist[4].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[4]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[4]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[4]), 2)
          }
        }
      }
    }
    arrObj.reward_fifth_level = statusReward
    testArr = arrDist[5].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[5]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[5]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[5]), 2)
          }
        }
      }
    }
    arrObj.reward_sixth_level = statusReward
    testArr = arrDist[6].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[6]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[6]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[6]), 2)
          }
        }
      }
    }
    arrObj.reward_seventh_level = statusReward
    arrObj.rewarded = objRewarded.toString()
    break;
  case '9':
    testArr = arrDist[0].users
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
          }
        }
      }
    }
    arrObj.reward_first_level = statusReward
    testArr = arrDist[1].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
          }
        }
      }
    }
    arrObj.reward_second_level = statusReward
    testArr = arrDist[2].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
          }
        }
      }
    }
    arrObj.reward_third_level = statusReward
    testArr = arrDist[3].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[3]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[3]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[3]), 2)
          }
        }
      }
    }
    arrObj.reward_fourth_level = statusReward
    testArr = arrDist[4].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[4]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[4]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[4]), 2)
          }
        }
      }
    }
    arrObj.reward_fifth_level = statusReward
    testArr = arrDist[5].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[5]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[5]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[5]), 2)
          }
        }
      }
    }
    arrObj.reward_sixth_level = statusReward
    testArr = arrDist[6].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[6]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[6]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[6]), 2)
          }
        }
      }
    }
    arrObj.reward_seventh_level = statusReward
    testArr = arrDist[7].users
    statusReward = 'completed but not rewarded'
    for (let ind = 0; ind < testArr.length; ind++) {
      const elementInd = testArr[ind];
      if (elementInd.name === arrObj.email) {
        statusReward = 'completed in ' + elementInd.sessions
        if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
          objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[7]), 2)
        } else {
          if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[7]), 2)
          } else {
            objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[7]), 2)
          }
        }
      }
    }
    arrObj.reward_eighth_level = statusReward
    arrObj.rewarded = objRewarded.toString()
    break;
  default:
    break;
}
}
  arrObj.status = 'active'
  } else {
    arrObj.status = 'not active'
  }
  arrTargets.push(arrObj)
}
}
        } else {
          const users = await getUsers(this.props.auth.getIdToken())
          for (let k = 0; k < users.length; k++) {
            const elementK = users[k];
            if (element.targets.includes(elementK.company_name) || element.targets.includes(elementK.email)) {
              arrObj.reward_first_level = 'not completed'
              arrObj.reward_second_level = 'not completed'
              arrObj.reward_third_level = 'not completed'
              arrObj.reward_fourth_level = 'not completed'
              arrObj.reward_fifth_level = 'not completed'
              arrObj.reward_sixth_level = 'not completed'
              arrObj.reward_seventh_level = 'not completed'
              arrObj.reward_eighth_level = 'not completed'
        arrObj.rewarded = '0'
        arrObj.type = elementK.type
        arrObj.email = elementK.email
      if (elementK.company_name !== 'unconfirmed') {
      arrObj.tag = elementK.company_name
      } else {
        arrObj.tag = elementK.name
      }
      if (elementK.tokens_gained && elementK.tokens_gained !== 'unconfirmed') {
      let tokenSplit = elementK.tokens_gained.split(',')
      if (tokenSplit.length > 1) {
        let objRewarded = 0
let statusReward = 'completed but not rewarded'
let testArr = []
      switch (tokenSplit[3]) {
        case '2':
          testArr = arrDist[0].users
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
                }
              }
            }
          }
          arrObj.reward_first_level = statusReward
          arrObj.rewarded = objRewarded.toString()
          break;
        case '3':
          testArr = arrDist[0].users
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
                }
              }
            }
          }
          arrObj.reward_first_level = statusReward
          testArr = arrDist[1].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
                }
              }
            }
          }
          arrObj.reward_second_level = statusReward
          arrObj.rewarded = objRewarded.toString()
          break;
        case '4':
          testArr = arrDist[0].users
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
                }
              }
            }
          }
          arrObj.reward_first_level = statusReward
          testArr = arrDist[1].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
                }
              }
            }
          }
          arrObj.reward_second_level = statusReward
          testArr = arrDist[2].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
                }
              }
            }
          }
          arrObj.reward_third_level = statusReward
          arrObj.rewarded = objRewarded.toString()
          break;
        case '5':
          testArr = arrDist[0].users
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
                }
              }
            }
          }
          arrObj.reward_first_level = statusReward
          testArr = arrDist[1].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
                }
              }
            }
          }
          arrObj.reward_second_level = statusReward
          testArr = arrDist[2].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
                }
              }
            }
          }
          arrObj.reward_third_level = statusReward
          testArr = arrDist[3].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[3]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[3]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[3]), 2)
                }
              }
            }
          }
          arrObj.reward_fourth_level = statusReward
          arrObj.rewarded = objRewarded.toString()
          break;
        case '6':
          testArr = arrDist[0].users
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
                }
              }
            }
          }
          arrObj.reward_first_level = statusReward
          testArr = arrDist[1].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
                }
              }
            }
          }
          arrObj.reward_second_level = statusReward
          testArr = arrDist[2].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
                }
              }
            }
          }
          arrObj.reward_third_level = statusReward
          testArr = arrDist[3].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[3]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[3]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[3]), 2)
                }
              }
            }
          }
          arrObj.reward_fourth_level = statusReward
          testArr = arrDist[4].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[4]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[4]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[4]), 2)
                }
              }
            }
          }
          arrObj.reward_fifth_level = statusReward
          arrObj.rewarded = objRewarded.toString()
          break;
        case '7':
          testArr = arrDist[0].users
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
                }
              }
            }
          }
          arrObj.reward_first_level = statusReward
          testArr = arrDist[1].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
                }
              }
            }
          }
          arrObj.reward_second_level = statusReward
          testArr = arrDist[2].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
                }
              }
            }
          }
          arrObj.reward_third_level = statusReward
          testArr = arrDist[3].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[3]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[3]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[3]), 2)
                }
              }
            }
          }
          arrObj.reward_fourth_level = statusReward
          testArr = arrDist[4].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[4]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[4]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[4]), 2)
                }
              }
            }
          }
          arrObj.reward_fifth_level = statusReward
          testArr = arrDist[5].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[5]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[5]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[5]), 2)
                }
              }
            }
          }
          arrObj.reward_sixth_level = statusReward
          arrObj.rewarded = objRewarded.toString()
          break;
        case '8':
          testArr = arrDist[0].users
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
                }
              }
            }
          }
          arrObj.reward_first_level = statusReward
          testArr = arrDist[1].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
                }
              }
            }
          }
          arrObj.reward_second_level = statusReward
          testArr = arrDist[2].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
                }
              }
            }
          }
          arrObj.reward_third_level = statusReward
          testArr = arrDist[3].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[3]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[3]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[3]), 2)
                }
              }
            }
          }
          arrObj.reward_fourth_level = statusReward
          testArr = arrDist[4].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[4]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[4]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[4]), 2)
                }
              }
            }
          }
          arrObj.reward_fifth_level = statusReward
          testArr = arrDist[5].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[5]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[5]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[5]), 2)
                }
              }
            }
          }
          arrObj.reward_sixth_level = statusReward
          testArr = arrDist[6].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[6]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[6]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[6]), 2)
                }
              }
            }
          }
          arrObj.reward_seventh_level = statusReward
          arrObj.rewarded = objRewarded.toString()
          break;
        case '9':
          testArr = arrDist[0].users
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[0]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[0]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[0]), 2)
                }
              }
            }
          }
          arrObj.reward_first_level = statusReward
          testArr = arrDist[1].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[1]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[1]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[1]), 2)
                }
              }
            }
          }
          arrObj.reward_second_level = statusReward
          testArr = arrDist[2].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[2]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[2]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[2]), 2)
                }
              }
            }
          }
          arrObj.reward_third_level = statusReward
          testArr = arrDist[3].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[3]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[3]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[3]), 2)
                }
              }
            }
          }
          arrObj.reward_fourth_level = statusReward
          testArr = arrDist[4].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[4]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[4]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[4]), 2)
                }
              }
            }
          }
          arrObj.reward_fifth_level = statusReward
          testArr = arrDist[5].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[5]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[5]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[5]), 2)
                }
              }
            }
          }
          arrObj.reward_sixth_level = statusReward
          testArr = arrDist[6].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[6]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[6]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[6]), 2)
                }
              }
            }
          }
          arrObj.reward_seventh_level = statusReward
          testArr = arrDist[7].users
          statusReward = 'completed but not rewarded'
          for (let ind = 0; ind < testArr.length; ind++) {
            const elementInd = testArr[ind];
            if (elementInd.name === arrObj.email) {
              statusReward = 'completed in ' + elementInd.sessions
              if (parseInt(elementInd.sessions) <= parseInt(max_sessions_first)) {
                objRewarded = fixRounding(objRewarded + parseInt(arrRewards_first[7]), 2)
              } else {
                if (parseInt(elementInd.sessions) <= parseInt(max_sessions_second)) {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_second[7]), 2)
                } else {
                  objRewarded = fixRounding(objRewarded + parseInt(arrRewards_third[7]), 2)
                }
              }
            }
          }
          arrObj.reward_eighth_level = statusReward
          arrObj.rewarded = objRewarded.toString()
          break;
        default:
          break;
      }
      }
        arrObj.status = 'active'
        } else {
          arrObj.status = 'not active'
        }
        arrTargets.push(arrObj)
            }
          }
        }
      }
    }
arrTargets.sort(function(a,b) {
  if (a.tag < b.tag) {
    return -1
  }
  if (a.tag > b.tag) {
    return 1
  }
  return 0
})
const arrFormatted: SingleRewardModel[] = arrSingle.concat(arrTargets)
const headersCsv = [
  {label: 'TYPE', key: 'type'},
  {label: 'EMAIL', key: 'email'},
  {label: 'NAME', key: 'tag'},
  {label: 'FIRST PRIZE MAX SESSIONS', key: 'sessions_first'},
  {label: 'SECOND PRIZE MAX SESSIONS', key: 'sessions_second'},
  {label: 'THIRD PRIZE MAX SESSIONS', key: 'sessions_third'},
  {label: 'FIRST LEVEL REWARDS (EUR)', key: 'rewards_first_level'},
  {label: 'SECOND LEVEL REWARDS (EUR)', key: 'rewards_second_level'},
  {label: 'THIRD LEVEL REWARDS (EUR)', key: 'rewards_third_level'},
  {label: 'FOURTH LEVEL REWARDS (EUR)', key: 'rewards_fourth_level'},
  {label: 'FIFTH LEVEL REWARDS (EUR)', key: 'rewards_fifth_level'},
  {label: 'SIXTH LEVEL REWARDS (EUR)', key: 'rewards_sixth_level'},
  {label: 'SEVENTH LEVEL REWARDS (EUR)', key: 'rewards_seventh_level'},
  {label: 'EIGHTH LEVEL REWARDS (EUR)', key: 'rewards_eighth_level'},
  {label: 'REWARDS START DATE', key: 'start_date'},
  {label: 'REWARDS END DATE', key: 'end_date'},
  {label: 'REWARDS CREATION DATE', key: 'creation_date'},
  {label: 'STATUS', key: 'status'},
  {label: 'AMOUNT REWARDED (EUR)', key: 'rewarded'}
]
let UnlimitedVar = `THIRD WINNERS IN MAX ${max_sessions_third} SESSIONS`
if (UnlimitedVar === rewardsConfig.max_session_third_prize) {
  UnlimitedVar = 'THIRD WINNERS IN UNLIMITED SESSIONS'
}
const headersCsvDist = [
  {label: `LEVEL`, key: 'level'},
  {label: `REWARDS (EUR)`, key: 'rewards'},
  {label: `FIRST WINNERS MAX ${max_sessions_first} SESSIONS`, key: 'prize_first'},
  {label: `SECOND WINNERS IN MAX ${max_sessions_second} SESSIONS`, key: 'prize_second'},
  {label: UnlimitedVar, key: 'prize_third'},
]
for (let u = 0; u < arrDist.length; u++) {
  const elementU = arrDist[u];
  arrDistFormat.push({level: elementU.level, rewards: `${arrRewards_first[parseInt(elementU.level) - 1]},${arrRewards_second[parseInt(elementU.level) - 1]},${arrRewards_third[parseInt(elementU.level) - 1]}`})
  let prize_first = 0
  let prize_second = 0
  let prize_third = 0
  for (let l = 0; l < elementU.users.length; l++) {
    const elementAt = elementU.users[l];
    if (parseInt(elementAt.sessions) <= parseInt(max_sessions_first)) {
      prize_first += 1
    } else {
      if (parseInt(elementAt.sessions) <= parseInt(max_sessions_second)) {
        prize_second += 1
      } else {
        prize_third += 1
      }
    }
  }
  arrDistFormat.push({level: elementU.level, rewards: `${arrRewards_first[parseInt(elementU.level) - 1]},${arrRewards_second[parseInt(elementU.level) - 1]},${arrRewards_third[parseInt(elementU.level) - 1]}`, prize_first: prize_first.toString(), prize_second: prize_second.toString(), prize_third: prize_third.toString()})
}
    this.setState({exportEnabled: true, loadingbutton: false, csvDistOptions: {
      data: arrDistFormat,
headers: headersCsvDist,
filename: 'vvip-reward-distribution.csv'
    }, csvSingleOptions: {
      data: arrFormatted,
headers: headersCsv,
filename: 'vvip-reward-single-report.csv'
    }})
  } catch (e) {
    this.setState({loadingbutton: false})
    alert('Could not load the CSV!')
  }
}

  handleReturn = () => {
    this.setState({ exportEnabled: false, sendDelete: 'none', statusDel: 'delete process not started yet.', idCheck: [],
    idView: [],
    formInput: '',
    selectCheck: false,
    selectClear: true})
  }

handleAdvCreate = () => {
  this.props.history.push('/advancedreward/' + JSON.stringify(this.state.rewardTargets))
}

handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ formInput: event.target.value })
}

  renderAdminUsersList() {
    let usersState = 'zero'
    if (this.state.idCheck.length > 1) {
      usersState = 'many'
    }
    if (this.state.idCheck.length === 1) {
      usersState = 'one'
    }
    switch (usersState) {
      case 'zero':
        return this.renderUsersZero()
      case 'many':
        return this.renderUsersMany()
      case 'one':
        return this.renderUsersOne()
      default:
        return this.renderUsersZero()
    }
  }

  renderUsersMany() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    return (
      <div>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
          <Grid.Row columns={5} centered>
            <Grid.Column textAlign="center">
            <Button loading={this.state.loadingbutton} color='violet' size='medium' onClick={this.handleClickDelete}>
Delete
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button disabled loading={this.state.loadingbutton} color='violet' size='medium' onClick={this.handleSingleCsv}>
{!this.state.exportEnabled && 'Load Single Report'}{this.state.exportEnabled && 'Reload Report'}
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
           loading={this.state.loadingbutton}
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
           loading={this.state.loadingbutton}
              color="orange"
                size="small"
                disabled={!this.state.exportEnabled}
                className="select-button"
                onClick={this.handleSingleDownload}
              >
                Export Single Report
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvSingleOptions}><div style={{display: 'none'}} ref={this.csvSingleLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
           loading={this.state.loadingbutton}
              color="orange"
                size="small"
                disabled={!this.state.exportEnabled}
                className="select-button"
                onClick={this.handleDistDownload}
              >
                Export Distribution
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvDistOptions}><div style={{display: 'none'}} ref={this.csvDistLink}></div></CSVLink>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={16} style={{minWidth: '250em'}}>
        <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Select</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Status</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Amount Rewarded</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Tag</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Creation Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Start Date - End Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Targets</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Max Sessions</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>First Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Second Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Third Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Fourth Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Fifth Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Sixth Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Seventh Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Eighth Level Rewards</Grid.Column>          
          </Grid.Row>
          {this.state.rewards.map((reward) => {
            if (!this.state.formInput) {
              idArray.push(reward.id)
              if (reward.id === this.state.rewards[this.state.rewards.length - 1].id) {
                this.handleCreateAllUserId(idArray)
             }
              return (
                <CheckElementReward
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
            )            
            } else {
                if (reward.tag.toLowerCase().includes(this.state.formInput.toLowerCase()) || reward.targets.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(reward.id)
                  if (reward.id === this.state.rewards[this.state.rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementReward
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (reward.id === this.state.rewards[this.state.rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
                            
            }            
          })}
          {this.state.rewards.length === 0 && <Grid.Row column={16} style={{minWidth: '250em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersZero() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    return (
      <div>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
        <Grid.Row columns={5} centered>
            <Grid.Column textAlign="center">
            <Button loading={this.state.loadingbutton} color='violet' size='medium' onClick={this.handleClickDelete}>
Delete
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button disabled loading={this.state.loadingbutton} color='violet' size='medium' onClick={this.handleSingleCsv}>
            {!this.state.exportEnabled && 'Load Single Report'}{this.state.exportEnabled && 'Reload Report'}
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
           loading={this.state.loadingbutton}
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
           loading={this.state.loadingbutton}
              color="orange"
                size="small"
                disabled={!this.state.exportEnabled}
                className="select-button"
                onClick={this.handleSingleDownload}
              >
                Export Single Report
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvSingleOptions}><div style={{display: 'none'}} ref={this.csvSingleLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
           loading={this.state.loadingbutton}
              color="orange"
                size="small"
                disabled={!this.state.exportEnabled}
                className="select-button"
                onClick={this.handleDistDownload}
              >
                Export Distribution
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvDistOptions}><div style={{display: 'none'}} ref={this.csvDistLink}></div></CSVLink>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>          
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={16} style={{minWidth: '250em'}}>
        <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Select</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Status</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Amount Rewarded</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Tag</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Creation Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Start Date - End Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Targets</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Max Sessions</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>First Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Second Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Third Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Fourth Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Fifth Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Sixth Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Seventh Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Eighth Level Rewards</Grid.Column>        
          </Grid.Row>
          {this.state.rewards.map((reward) => {
            if (!this.state.formInput) {
              idArray.push(reward.id)
              if (reward.id === this.state.rewards[this.state.rewards.length - 1].id) {
                this.handleCreateAllUserId(idArray)
             }
              return (
                <CheckElementReward
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
            )            
            } else {
                if (reward.tag.toLowerCase().includes(this.state.formInput.toLowerCase()) || reward.targets.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(reward.id)
                  if (reward.id === this.state.rewards[this.state.rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementReward
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (reward.id === this.state.rewards[this.state.rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
                            
            }            
          })}
          {this.state.rewards.length === 0 && <Grid.Row column={16} style={{minWidth: '250em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderUsersOne() {
    this.handleDeleteAllUserId()
    let idArray: string[] = []
    return (
      <div>
        <Grid centered stackable relaxed columns='equal' style={{marginTop: '0.1em'}}>
        <Grid.Row columns={5} centered>
            <Grid.Column textAlign="center">
            <Button loading={this.state.loadingbutton} color='violet' size='medium' onClick={this.handleClickDelete}>
Delete
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button loading={this.state.loadingbutton} color='violet' size='medium' onClick={this.handleSingleCsv}>
            {!this.state.exportEnabled && 'Load Single Report'}{this.state.exportEnabled && 'Reload Report'}
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
           loading={this.state.loadingbutton}
              color="orange"
                size="small"
                className="select-button"
                onClick={this.handleDownload}
              >
                Export CSV
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvOptions}><div style={{display: 'none'}} ref={this.csvLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
           loading={this.state.loadingbutton}
              color="orange"
                size="small"
                disabled={!this.state.exportEnabled}
                className="select-button"
                onClick={this.handleSingleDownload}
              >
                Export Single Report
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvSingleOptions}><div style={{display: 'none'}} ref={this.csvSingleLink}></div></CSVLink>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center">
          <div>
          <Button
           loading={this.state.loadingbutton}
              color="orange"
                size="small"
                disabled={!this.state.exportEnabled}
                className="select-button"
                onClick={this.handleDistDownload}
              >
                Export Distribution
              </Button>
              <CSVLink style={{display: 'none'}} {...this.state.csvDistOptions}><div style={{display: 'none'}} ref={this.csvDistLink}></div></CSVLink>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} centered style={{marginTop: '-0.9em'}}>
            <Grid.Column textAlign="center">
                  <input
                  style={{width: '100%'}}
                    placeholder="Search"
                    value={this.state.formInput}
                    onChange={this.handleNameChange}
                  />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleSelectAll}
              >
                Select all
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button
                color="orange"
                size="small"
                className="select-button"
                onClick={this.handleClear}
              >
                Clear all
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid container  celled verticalAlign="middle" columns="equal" style={{ backgroundColor: "white", maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', fontSize: '0.6rem'}}>
        <Grid.Row columns={16} style={{minWidth: '250em'}}>
        <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Select</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Status</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Amount Rewarded</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Tag</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Creation Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Start Date - End Date</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Targets</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Max Sessions</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>First Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Second Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Third Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Fourth Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Fifth Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Sixth Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Seventh Level Rewards</Grid.Column>
            <Grid.Column style={{fontWeight: "bold", color: '#6435c9', overflow: 'auto', padding: '0.2em'}}>Eighth Level Rewards</Grid.Column>         
          </Grid.Row>
          {this.state.rewards.map((reward) => {
            if (!this.state.formInput) {
              idArray.push(reward.id)
              if (reward.id === this.state.rewards[this.state.rewards.length - 1].id) {
                this.handleCreateAllUserId(idArray)
             }
              return (
                <CheckElementReward
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
            )            
            } else {
                if (reward.tag.toLowerCase().includes(this.state.formInput.toLowerCase()) || reward.targets.toLowerCase().includes(this.state.formInput.toLowerCase())) {
                  idArray.push(reward.id)
                  if (reward.id === this.state.rewards[this.state.rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                  return (
                    <CheckElementReward
                  reward={reward}
                  onCheck={this.handleIdCheckPush}
                  onUnCheck={this.handleIdCheckDelete}
                  userId={reward.id}
                  onSelectCheck={this.state.selectCheck}
                  onSelectClear={this.state.selectClear}
                />
                )
                } else {
                  if (reward.id === this.state.rewards[this.state.rewards.length - 1].id) {
                    this.handleCreateAllUserId(idArray)
                 }
                }
                            
            }            
          })}
          {this.state.rewards.length === 0 && <Grid.Row column={16} style={{minWidth: '250em'}}>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
              <Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column><Grid.Column style={{overflow: 'auto', padding: '0.2em'}}>None Available</Grid.Column>
        </Grid.Row>}
        </Grid>
      </div>
    )
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading Rewards
        </Loader>
      </Grid.Row>
    )
  }

  renderButtons() {
      return (
        <div>
          {this.state.sendDelete === 'none' && <Grid centered relaxed columns='equal'>
            <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
              <Button color="orange" size="medium" loading={this.state.loadingbutton} onClick={this.handleAdvCreate}>
        Create a new Group of Rewards
      </Button>
              </Grid.Column>
            </Grid.Row>
            </Grid>}
            {(this.state.sendDelete === 'delete') && <Grid centered relaxed columns='equal'>
            <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
              <Button loading={this.state.loadingbutton} color="orange" size="big" onClick={this.handleReturn}>
        Return
      </Button>
              </Grid.Column>
            </Grid.Row>
            </Grid>}
        </div>
      )      
  }

  renderRewardList() {
    return (
      <div>
          <HeaderCustom headerType="reward_status"/>
          {this.state.sendDelete === 'none' && this.renderAdminUsersList()}
              {this.state.sendDelete === 'delete' && <Grid centered relaxed columns='equal' style={{marginTop: '8px'}}>
              {this.state.statusDel !== 'rewards deleted successfully!' && <Grid.Row columns={1} centered>
                  <Grid.Column textAlign='center'>
                    <Message size='big' warning><Message.Header>Are you sure?</Message.Header><p>This Group of Rewards will be deleted permanently</p></Message>
                  </Grid.Column>
                </Grid.Row>}
          {this.state.statusDel === 'delete process not started yet.' && <Grid.Row columns={6}>
            <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
            <Grid.Column textAlign="center">
            <Button color='violet' size='large' onClick={this.handleYes}>
Yes
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button color='violet' size='large' onClick={this.handleNo}>
No
            </Button>
            </Grid.Column>
            <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>}
          {this.state.statusDel === 'deleting rewards...' && <Grid.Row columns={6}>
            <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
            <Grid.Column textAlign="center">
            <Button disabled color='violet' size='large' onClick={this.handleYes}>
Yes
            </Button>
            </Grid.Column>
            <Grid.Column textAlign="center">
            <Button disabled color='violet' size='large' onClick={this.handleNo}>
No
            </Button>
            </Grid.Column>
            <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>}
          {this.state.statusDel === 'rewards deleted successfully!' && <Grid.Row centered columns={1}>
          <Grid.Column textAlign='center'>
          <Message size='big' color='violet'><Message.Header>Process Completed!</Message.Header><p>The group of Rewards was deleted. You can return to your Rewards List</p></Message>
          </Grid.Column>
            </Grid.Row>}
          <Grid.Row columns={1} centered>
                  <Grid.Column textAlign='center'>
                    <Message size='tiny' compact color='violet'><Message.Header>STATUS</Message.Header><p>{this.state.statusDel}</p></Message>
                    </Grid.Column>
                </Grid.Row>
          </Grid>}
              </div>
    )
  }

  renderCompletePage() {
return (
  <div>
{this.renderRewardList()}
  
  {this.renderButtons()}
  </div>
)
  }

render() {
  if (this.state.loading) {
    return this.renderLoading()
  }
  return (
    <Grid centered relaxed columns='equal'>
              <Grid.Row columns={1} centered>
                <Grid.Column verticalAlign="middle" textAlign="center">
                    {this.renderCompletePage()}                               
                </Grid.Column>
              </Grid.Row>            
            </Grid>
  )
}

}