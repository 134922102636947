/* eslint-disable array-callback-return */
import * as React from 'react'
import { UserInfoCard } from './UserInfoCard'
import { getInfoUser } from '../api/users-api'
import { Card, Divider, Grid, Loader, Button } from 'semantic-ui-react'
import { Auth } from '../auth/Auth'
import { UserInfoModel } from '../types/UserInfoModel'
import { HeaderCustom } from './HeaderCustom'
const { currency } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

interface UserInfoProps {
  match: {
    params: {
      userId: string
    }
  },
  auth: Auth,
  history: any
}

interface UserInfoState {
  user: UserInfoModel,
  loading: boolean
}

export class InfoUser extends React.PureComponent<
  UserInfoProps,
  UserInfoState
> {
  state: UserInfoState = {
    user: {
      Name: '',
      Email: '',
      Expiration: '',
      Group: '',
      Autorenew: '',
      Charge: '',
      Accounts: '',
      Activated: '',
      Duration: '',
      Coupon: '',
      ExGroup: '',
      Type: ''
    },
    loading: true
  }

  async componentDidMount() {
    try {
      const user = await getInfoUser(
        this.props.auth.getIdToken(),
        this.props.match.params.userId
      )
      let Expiration: string = 'none'
      if (user.payment !== 'unconfirmed') {
        user.usergroup === 'admins' || user.usergroup === 'goldenadmins' ? Expiration = user.payment : Expiration = new Date(parseInt(user.payment)).toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year: 'numeric'})
      }
      let userInfo: UserInfoModel = {
        Name: user.name,
        Email: user.email,
        Expiration,
        Group: user.usergroup,
        Autorenew: user.renew,
        Charge: user.charge,
        Accounts: user.numberofusers,
        Activated: user.usersactivated,
        Duration: user.timepayment,
        Coupon: user.coupon
      }
      if (user.company_name) {
userInfo.Company = user.company_name
      }
      if (user.numberofusers === '1000000000') {
userInfo.Accounts = 'Unlimited'
      }
      this.setState({
        user: userInfo,
        loading: false
      })
    } catch (e) {
      console.log(`Failed to fetch user: `,{e})
    }
  }

  handleReturn = () => {
    this.props.history.replace(`/`)
  }

  renderLoading() {
    return (
      <Grid.Row>
        <Loader indeterminate active inline="centered">
          Loading User Info
        </Loader>
      </Grid.Row>
    )
  }

  renderInfos() {
    let group: string = 'none'
    let checkbool: boolean = false
    return (
      <div>
        <HeaderCustom headerType="info"/>
        <Divider clearing />
        <Card.Group centered>
          {Object.entries(this.state.user).map((item) => {
            if (item[1]) {
            checkbool = false
            if (item[0] === 'Group') {
if (item[1] === 'companies') {
  group = 'companies'
}
            }
            if (group === 'companies') {
if (item[0] === 'Accounts') {
  if (item[1] === '1') {
    checkbool = true
    return (
      <UserInfoCard attributeKey='Employees Available' attributeValue='0' />
    )
  } else {
    checkbool = true
  return (
    <UserInfoCard attributeKey='Employees Available' attributeValue={item[1]} />
  )
  }
}
if (item[0] === 'Activated') {
    checkbool = true
  return (
    <UserInfoCard attributeKey='Registered Employees' attributeValue={item[1]} />
  )  
}
            }
            if (group !== 'companies') {
if (item[0] === 'Activated') {
checkbool = true
return (
  <UserInfoCard attributeKey='Registered Accounts' attributeValue='1' />
)
}
            }
            if (item[0] === 'Charge') {
              if (item[1] && item[1] !== 'none') {
                checkbool = true
                let chargeVar = item[1] + ' ' + currency
                return (
                  <UserInfoCard attributeKey={item[0]} attributeValue={chargeVar} />
                )
              } else {
                checkbool = true
                return (
                  <UserInfoCard attributeKey={item[0]} attributeValue={'none'} />
                )
              }
            }
            if (!checkbool) {
              return (
                <UserInfoCard attributeKey={item[0]} attributeValue={item[1]} />
              )
            }
          }
          })}
        </Card.Group>
        <Divider clearing />
        <Grid centered relaxed columns='equal'>
        <Grid.Row columns={1} centered>
              <Grid.Column textAlign="center">
        <Button
                color = "violet"
                  size="large"
                  className="action-button"
                  onClick={this.handleReturn}
                >
                  Return
                </Button>
                </Grid.Column>
            </Grid.Row>
            </Grid>
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading()
    }
    return this.renderInfos()
  }
}
