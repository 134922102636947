import * as React from 'react'
import { Header, Icon } from 'semantic-ui-react'

interface headerProps {
  headerType: String
}

interface headerState {}

export class HeaderCustom extends React.PureComponent<headerProps, headerState> {
  renderEmail() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='mail' size='tiny' />
    Email Settings
    <Header.Subheader>
      Sending email to your users
    </Header.Subheader>
  </Header>
    )
  }

  renderGoldenCreation() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='chain' size='tiny' />
    Atlanticoin Account Creation
    <Header.Subheader>
    Link Virtual Vip to Atlanticoin and get Exclusive Features
    </Header.Subheader>
  </Header>
    )
  }

  renderInvoices() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='archive' size='tiny' />
    Invoice List
    <Header.Subheader>
    All Invoices Available
    </Header.Subheader>
  </Header>
    )
  }

  renderGoldenStatus() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='sitemap' size='tiny' />
    Atlanticoin Management
    <Header.Subheader>
    Check your Golden Coins
    </Header.Subheader>
  </Header>
    )
  }

  renderServiceFailure() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='thumbs down' size='tiny' />
    System Offline
    <Header.Subheader>
    Backoffice Service Suspended
    </Header.Subheader>
  </Header>
    )
  }

  renderGoldenBoost() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='bolt' size='tiny' />
    Boost Operation
    <Header.Subheader>
    Balance Allocation Process
    </Header.Subheader>
  </Header>
    )
  }

  renderRedemption() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='users' size='tiny' />
    Redemption Arc
    <Header.Subheader>
      Track your targets
    </Header.Subheader>
  </Header>
    )
  }

  renderPaymentSave() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='tiny' icon color='violet'>
    <Icon name='users' size='mini' />
    Save your New Card
    <Header.Subheader>
      Stripe will take care of the process
    </Header.Subheader>
  </Header>
    )
  }

  renderInfo() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='info' size='tiny' />
    User Details
    <Header.Subheader>
      Viewing info of your users
    </Header.Subheader>
  </Header>
    )
  }

  renderDelete() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='delete' size='tiny' />
    User Deletion
    <Header.Subheader>
      Deleting your users
    </Header.Subheader>
  </Header>
    )
  }

  renderPaymentStatus() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='dashboard' size='tiny' />
    Account status
    <Header.Subheader>
      Billing Details
    </Header.Subheader>
  </Header>
    )
  }

  renderCouponStatus() {
    return (
      <Header style={{marginBottom: "-0.7em"}} size='small' icon color='violet'>
    <Icon name='dashboard' size='tiny' />
    Coupons
    <Header.Subheader>
      All coupons available
    </Header.Subheader>
  </Header>
    )
  }

  renderRewardStatus() {
    return (
      <Header style={{marginBottom: "-0.7em"}} size='small' icon color='violet'>
    <Icon name='dashboard' size='tiny' />
    Rewards
    <Header.Subheader>
      All rewards available
    </Header.Subheader>
  </Header>
    )
  }

  renderCouponCreation() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='ticket' size='tiny' />
    Creation
    <Header.Subheader>
     Add a coupon to the list
    </Header.Subheader>
  </Header>
    )
  }

  renderPaymentOne() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='time' size='tiny' />
    First Step
    <Header.Subheader>
      Account Duration
    </Header.Subheader>
  </Header>
    )
  }

  renderPaymentTwo() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='payment' size='tiny' />
    Second Step
    <Header.Subheader>
      Payment Options
    </Header.Subheader>
  </Header>
    )
  }

  renderPaymentThree() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='cart' size='tiny' />
    Third Step
    <Header.Subheader>
      Account Summary
    </Header.Subheader>
  </Header>
    )
  }

  renderUsers() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='users' size='tiny' />
    User List
    <Header.Subheader>
     Managing your users
    </Header.Subheader>
  </Header>
    )
  }

  renderCreate() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='add user' size='tiny' />
    User Creation
    <Header.Subheader>
     Creating new user
    </Header.Subheader>
  </Header>
    )
  }

  renderGateway() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='payment' size='tiny' />
    Insert your Payment Details
    <Header.Subheader>
     Required to proceed with the Payment Process
    </Header.Subheader>
  </Header>
    )
  }

  renderConfirmation() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='hand peace' size='tiny' />
    You are now a Member of Virtual Vip
    <Header.Subheader>
     Your Payment was Successful!
    </Header.Subheader>
  </Header>
    )
  }

  renderSuccesfull() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='hand peace' size='tiny' />
    Congratulations!
    <Header.Subheader>
     Your Payment was Successful!
    </Header.Subheader>
  </Header>
    )
  }

  renderFailure() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='thumbs down' size='tiny' />
    Payment Failed!
    <Header.Subheader>
     Something went Wrong during the Process
    </Header.Subheader>
  </Header>
    )
  }

  renderCampaignFailure() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='thumbs down' size='tiny' />
    Campaign Failed!
    <Header.Subheader>
     Something went Wrong during the Process
    </Header.Subheader>
  </Header>
    )
  }

  renderCampaignStatus() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='archive' size='tiny' />
    Campaign List
    <Header.Subheader>
    All campaigns available
    </Header.Subheader>
  </Header>
    )
  }

  renderCampaignProcess() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='calendar alternate' size='tiny' />
    Campaign Creation
    <Header.Subheader>
      Configure your Campaign
    </Header.Subheader>
  </Header>
    )
  }

  renderTicketStatus() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='archive' size='tiny' />
    Ticket List
    <Header.Subheader>
    All tickets available
    </Header.Subheader>
  </Header>
    )
  }

  renderTicketProcess() {
    return (
      <Header style={{marginBottom: "-0.9em"}} size='small' icon color='violet'>
    <Icon name='magic' size='tiny' />
    Ticket Creation
    <Header.Subheader>
    Troubleshooting process
    </Header.Subheader>
  </Header>
    )
  }

  render() {
    let text = this.props.headerType
    switch (text) {
      case "ticket_status":
       return this.renderTicketStatus()
      case "ticket_process":
       return this.renderTicketProcess()
      case "campaign_failure":
       return this.renderCampaignFailure()
      case "campaign_process":
       return this.renderCampaignProcess()
      case "campaign_status":
       return this.renderCampaignStatus()
      case "coupon_creation":
       return this.renderCouponCreation()
      case "coupon_status":
       return this.renderCouponStatus()
      case "reward_status":
       return this.renderRewardStatus()
      case "invoice_status":
       return this.renderInvoices()
      case "email":
       return this.renderEmail()
      case "info":
       return this.renderInfo()
      case "delete":
       return this.renderDelete()
      case "users":
       return this.renderUsers()
      case "create":
       return this.renderCreate()
      case "payment_status":
       return this.renderPaymentStatus()
      case "payment_1":
       return this.renderPaymentOne()
      case "payment_2":
       return this.renderPaymentTwo()
      case "payment_3":
       return this.renderPaymentThree()
      case "pay":
       return this.renderGateway()
      case "paymentfailure":
       return this.renderFailure()
      case "paymentconfirmation":
       return this.renderConfirmation()
      case "redemptionusers":
       return this.renderRedemption()
      case "paymentsuccesfull":
       return this.renderSuccesfull()
      case "payment_storage":
       return this.renderPaymentSave()
      case "golden_status":
       return this.renderGoldenStatus()
      case "golden_boost":
       return this.renderGoldenBoost()
      case "golden_creation":
       return this.renderGoldenCreation()
      case "servicefailure":
       return this.renderServiceFailure()
      default:
        return (
          <div>
            <h1>Title Undefined</h1>
          </div>
        )
    }    
  }
}
