import * as React from 'react'
import { Checkbox,Grid } from 'semantic-ui-react'
import { RewardFormatted } from '../types/RewardModels'

const { rewardsConfig } = require(`../config-${process.env.REACT_APP_AWS_ACCOUNT}`)

interface CheckElementProps {
    userId: string,
    onUnCheck: any,
    onCheck: any,
    onSelectCheck: boolean,
    onSelectClear: boolean,
    reward: RewardFormatted
  }
  
  interface CheckElementState {
      checked: boolean,
      checkselect: boolean
  }
  
  export class CheckElementReward extends React.PureComponent<CheckElementProps, CheckElementState> {
    state: CheckElementState = {
        checked: false,
        checkselect: false
      }
    toggle = () => {this.setState((prevState: any) => ({ checked: !prevState.checked }))}
    handleCheck = () => {this.props.onCheck(this.props.userId)}
    handleUnCheck = () => {this.props.onUnCheck(this.props.userId)}
    shouldComponentUpdate(nextProps: CheckElementProps, nextState: CheckElementState) {
      if (this.props.onSelectCheck !== nextProps.onSelectCheck) {
          this.setState({ checked: true, checkselect: !this.state.checkselect })
          return false
      }
    if (this.props.onSelectClear !== nextProps.onSelectClear) {
        this.setState({ checked: false, checkselect: !this.state.checkselect })
        return false
    }    
    if (this.state.checkselect === nextState.checkselect) {
      if (this.state.checked !== nextState.checked) {
        if (nextState.checked) {
    this.handleCheck()
    } else {
      this.handleUnCheck()
      }
    }
  }
return true
}
    render() {
      const targetSplit = this.props.reward.targets.split(',')
      let targetValue = 'All Users (including new Customers)'
      if (targetSplit.length > 1) {
        targetSplit.shift()
        targetValue = 'All Users (including new Customers BUT excluding ' + targetSplit.toString() + ')'
      }
      return (
        <Grid.Row style={{minWidth: '250em'}} column={16}>
          <Grid.Column style={{padding: "0.2em"}}>
                <Checkbox
                checked={this.state.checked}
          onClick={this.toggle}
        />
        </Grid.Column>
        <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.status}</Grid.Column>
        <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.rewarded} <strong>EUR</strong></Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.tag}</Grid.Column>
              <Grid.Column style={{padding: "0.2em"}}>{this.props.reward.creation_date}</Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.start_date} - {this.props.reward.end_date}</Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto', maxHeight: '10em'}}>{this.props.reward.targets.includes('all') && targetValue}{!this.props.reward.targets.includes('all') && this.props.reward.targets}</Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}><strong>First:</strong> {this.props.reward.sessions_first} - <strong>Second:</strong> {this.props.reward.sessions_second} - <strong>Third:</strong> {rewardsConfig.max_session_third_prize.toString() === this.props.reward.sessions_third && 'Unlimited'}{rewardsConfig.max_session_third_prize.toString() !== this.props.reward.sessions_third && this.props.reward.sessions_third}</Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.reward_first_level} <strong>EUR</strong></Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.reward_second_level} <strong>EUR</strong></Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.reward_third_level} <strong>EUR</strong></Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.reward_fourth_level} <strong>EUR</strong></Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.reward_fifth_level} <strong>EUR</strong></Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.reward_sixth_level} <strong>EUR</strong></Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.reward_seventh_level} <strong>EUR</strong></Grid.Column>
              <Grid.Column style={{padding: "0.2em", overflow: 'auto'}}>{this.props.reward.reward_eighth_level} <strong>EUR</strong></Grid.Column>      
        </Grid.Row>
      )
    }
  }